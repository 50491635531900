import { type Identifier, type MergeUnions } from 'appTypes'
import { type WithRemainDatePretty } from 'resources/common'
import { type MeterModel, type UnitModel } from 'resources/units'
import { type CK14Model, type CK33Model } from 'resources/vmrs'
import { type WorkOrderModel } from 'resources/workOrders'

export interface PmModel {
    componentData: CK33Model
    mostUrgentStatus: PMIntervalStatusKeys
    mostUrgentType: PMIntervalTypes
    id: Identifier
    name: string
    description: string
    component: Identifier
    lastDone: string
    reasonForRepair: Identifier
    reasonForRepairData: CK14Model
    intervals: IntervalsType[]
    isDependent: boolean
    workOrderData: WorkOrderModel
    notes: string
    rank: number
    unitData: UnitModel
}

export enum PMIntervalStatusKeys {
    PLANNED = 'PLANNED',
    OVERDUE = 'OVERDUE',
    DUE = 'DUE',
}

export type IntervalsType = PMIntervalTimeType | PMIntervalMeterType
export type PMIntervalTypes = MeterModel['type'] | 'TIME'

interface PMInterval<IntervalType extends PMIntervalTypes> {
    passed: number
    type: IntervalType
    value: number
    status: PMIntervalStatusKeys
    threshold: number
    passedPercentTillOverdue: number
}

export enum PMIntervalTimeTypeKeys {
    DAY = 'DAY',
    WEEK = 'WEEK',
    MONTH = 'MONTH',
    YEAR = 'YEAR',
}

export interface PMIntervalTimeType extends PMInterval<'TIME'>, WithRemainDatePretty {
    value: number
    now: string
    valueType: PMIntervalTimeTypeKeys
    thresholdType: PMIntervalTimeTypeKeys
    overdue: string
    due: string
}

export interface PMIntervalMeterType
    extends PMInterval<'ODOMETER' | 'HUBOMETER' | 'ENGINE_HOURS' | 'APU_HOURS'> {
    overdue: number
    now: number
    lastDoneMeterValue: number
}

export type PMIntervalIntersection = MergeUnions<IntervalsType>
