import { type FC } from 'react'

import Icons from 'assets/icons'
import { type DeleteOneParams, useDeleteOne } from 'core/actions'
import { basePermissions, renderOnPermission } from 'core/permissions'
import { Tooltip, type ButtonProps, Button } from 'ui'

import { useUtilityDrawerContext } from './UtilityDrawerContext'

export interface UtilityDrawerDeleteButtonProps extends Partial<DeleteOneParams> {
    disabled?: boolean
    title?: string
}

const UtilityDrawerDeleteButton: FC<UtilityDrawerDeleteButtonProps> = renderOnPermission(
    (props) => {
        const { forceClose, extraArgs } = useUtilityDrawerContext()
        const deleteOne = useDeleteOne()
        const onSuccess = () => {
            props.onSuccess?.()
            forceClose()
        }

        const { id, resource } = extraArgs

        if (!id) {
            return null
        }

        return (
            <ButtonView
                title={props.title ?? null}
                disabled={props.disabled}
                onClick={() => {
                    deleteOne({
                        id,
                        resource,
                        ...props,
                        redirectTo: '',
                        onSuccess,
                    })
                }}
            />
        )
    },
    basePermissions.destroy,
)

export default UtilityDrawerDeleteButton

const ButtonView = (props: ButtonProps) => {
    const { title, ...rest } = props
    const content = (
        <Button
            startIcon={<Icons.Delete />}
            size="small"
            color="error"
            {...rest}
            type="button"
        >
            Delete
        </Button>
    )
    return title ? (
        <Tooltip title={title}>
            <span>{content}</span>
        </Tooltip>
    ) : (
        <>{content}</>
    )
}
