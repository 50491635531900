import { type FC } from 'react'

import Icons from 'assets/icons'
import { useCreateResourcePath } from 'core/resource'
import { globalClassNames } from 'lib'
import { WidgetListContainer } from 'pages/Dashboard/components/WidgetBase/styled'
import { unitResource } from 'resources/units'
import { Spacer } from 'ui'

import { WidgetListDescription, WidgetListRowBase } from '../../components'
import { type ListType } from '../types'
import { formatValue } from '../utils'

interface Props {
    data: ListType
    description: string
}

export const DataList: FC<Props> = ({ data, description }) => {
    const createPath = useCreateResourcePath()

    const sortedData = data.length
        ? data.sort((a, b) => a.differenceWithAverage - b.differenceWithAverage)
        : data

    const list = sortedData
        .slice(0, 3)
        .map(({ number, differenceWithAverage, id, mtbf, archived }) => {
            const isBelowAvg = differenceWithAverage <= 0
            const unitEditUrl = createPath({
                resource: unitResource.resource,
                id,
                type: 'edit',
            })

            const ArrowIcon = isBelowAvg ? Icons.TrendingDownOutlined : Icons.TrendingUpOutlined

            return (
                <WidgetListRowBase
                    key={id}
                    link={unitEditUrl}
                    icon={(render) =>
                        archived ? render({ icon: Icons.Archived, tooltip: 'Archived' }) : undefined
                    }
                    label={number}
                    clarification={
                        <>
                            {formatValue(Math.abs(differenceWithAverage))}
                            {isBelowAvg ? ' below ' : ' above '}
                            average
                        </>
                    }
                    total={
                        <Spacer className={globalClassNames.noWrap}>
                            <ArrowIcon
                                sx={(theme) => ({
                                    width: '16px',
                                    height: '16px',
                                    color: theme.palette.charts[isBelowAvg ? 'red' : 'greenBody'],
                                })}
                            />
                            {formatValue(mtbf)}
                        </Spacer>
                    }
                />
            )
        })
    if (!list.length) {
        return <WidgetListDescription>{description}</WidgetListDescription>
    }

    return <WidgetListContainer>{list}</WidgetListContainer>
}
