import { type InvoiceItemKeys } from 'resources/invoices'
import { LineItemTypeKeys } from 'resources/lineItems'

import { InvoiceLaborDrawer } from './InvoiceLaborDrawer'
import InvoicePartDrawer from './InvoicePartDrawer'
import InvoiceServiceDrawer from './InvoiceServiceDrawer'

const ItemTypeDrawerMap = {
    [LineItemTypeKeys.PART]: InvoicePartDrawer,
    [LineItemTypeKeys.LABOR]: InvoiceLaborDrawer,
    [LineItemTypeKeys.SERVICE]: InvoiceServiceDrawer,
}

export const getInvoiceItemDrawer = (type: InvoiceItemKeys) => ItemTypeDrawerMap[type]
