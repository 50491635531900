import { type FC } from 'react'

import { DateTimeInput, inputIntegerNonNegativeSpacedMaskParams, TextInput } from 'components'
import { requiredValidation } from 'core'
import { PMLastMetersContextProvider, PMLastMetersFetch, type PmModel } from 'resources/pm'
import { getMeterTypeAdornment, meterTypesConfig, type UnitModel } from 'resources/units'
import { Alert, Stack, Typography, StyledElement } from 'ui'
import { camelCase } from 'utils'

import UnitPMCardResetLastDoneHelperText from './UnitPMCardResetLastDoneHelperText'

interface ResetPmContentProps {
    unitPmRecord: PmModel
    record: UnitModel
    data: PmModel[]
}

const ResetPmContent: FC<ResetPmContentProps> = ({ unitPmRecord, record, data }) => {
    return (
        <>
            {unitPmRecord.isDependent && unitPmRecord.rank !== 1 ? (
                <Alert
                    severity="warning"
                    sx={{
                        marginBottom: '22px',
                        color: 'red',
                    }}
                >
                    Resetting this interval will also reset all its dependent intervals:
                    <Stack>
                        {data.map((pm) => {
                            if (pm.rank >= unitPmRecord.rank) {
                                return null
                            }
                            return (
                                <Typography
                                    color="inherit"
                                    fontWeight="500"
                                >
                                    • {pm.name}
                                </Typography>
                            )
                        })}
                    </Stack>
                </Alert>
            ) : null}
            <PMLastMetersContextProvider>
                <PMLastMetersFetch
                    unitId={record.id}
                    archived={record.archived}
                />
                <DateTimeInput
                    source="lastDone"
                    label="Last Done Date"
                    disableFuture
                    validate={requiredValidation}
                    defaultValue={new Date()}
                />
                {unitPmRecord.isDependent
                    ? Object.values(
                          data.reduce((initObj, pm, i) => {
                              const { intervals } = pm
                              if (pm.rank > unitPmRecord.rank) {
                                  return initObj
                              }
                              intervals.forEach((interval) => {
                                  if (interval.type === 'TIME' || initObj[interval.type]) {
                                      return null
                                  }
                                  const name = `${camelCase(interval.type)}Value`
                                  initObj[interval.type] = (
                                      <>
                                          <Typography
                                              variant="subtitle2"
                                              mb="11px"
                                          >
                                              {meterTypesConfig[interval.type]?.name}
                                          </Typography>
                                          <TextInput
                                              key={i}
                                              source={name}
                                              helperText={
                                                  <UnitPMCardResetLastDoneHelperText
                                                      interval={interval}
                                                      name={name}
                                                  />
                                              }
                                              label="Reading at Last Done Date"
                                              validate={requiredValidation}
                                              {...inputIntegerNonNegativeSpacedMaskParams}
                                              InputProps={{
                                                  ...inputIntegerNonNegativeSpacedMaskParams.InputProps,
                                                  endAdornment: getMeterTypeAdornment(
                                                      interval.type,
                                                  ),
                                              }}
                                          />
                                      </>
                                  )
                              })
                              return initObj
                          }, {}),
                      )
                    : unitPmRecord.intervals.map((interval, i) => {
                          if (interval.type === 'TIME') {
                              return null
                          }

                          const name = `${camelCase(interval.type)}Value`

                          return (
                              <StyledElement key={i}>
                                  <Typography
                                      variant="subtitle2"
                                      mb="11px"
                                  >
                                      {meterTypesConfig[interval.type]?.name}
                                  </Typography>
                                  <TextInput
                                      source={name}
                                      helperText={
                                          <UnitPMCardResetLastDoneHelperText
                                              interval={interval}
                                              name={name}
                                          />
                                      }
                                      label="Reading at Last Done Date"
                                      validate={requiredValidation}
                                      {...inputIntegerNonNegativeSpacedMaskParams}
                                      InputProps={{
                                          ...inputIntegerNonNegativeSpacedMaskParams.InputProps,
                                          endAdornment: getMeterTypeAdornment(interval.type),
                                      }}
                                  />
                              </StyledElement>
                          )
                      })}
            </PMLastMetersContextProvider>
        </>
    )
}

export default ResetPmContent
