import { type FC, type ReactElement } from 'react'

import { type Identifier } from 'appTypes'
import { useOpenUtilityDrawer, UtilityDrawerEditor } from 'components'
import { type Serializer } from 'core'
import { getInvoiceItemResource, type InvoiceTax } from 'resources/invoices'

import ManageTaxForm from './ManageTaxForm'

interface Props {
    children: (params: () => void) => ReactElement
    id: Identifier
    invoiceId: Identifier
    readOnly?: boolean
}

const ManageTaxDrawerToggler: FC<Props> = ({ children, invoiceId, id, readOnly }) => {
    const open = useOpenUtilityDrawer()
    const resource = getInvoiceItemResource(invoiceId)

    return children(() => {
        open({
            extraArgs: {
                resource,
                id,
            },
            drawerArgs: {
                title: readOnly ? 'View Tax' : 'Edit Tax',
                renderWrapper: (params) => (
                    <UtilityDrawerEditor
                        serializer={serializer}
                        {...params}
                    />
                ),
                renderContent: () => <ManageTaxForm isInputDisabled={() => readOnly} />,
                renderTopRight: readOnly
                    ? null
                    : (render) =>
                          render({
                              confirmConfig: {
                                  title: 'Are you sure you want to delete this Tax?',
                              },
                          }),
                renderBottomLeft: readOnly ? (render) => render({ children: 'Close' }) : undefined,
                renderBottomRight: readOnly ? null : (render) => render(),
            },
        })
    })
}

export default ManageTaxDrawerToggler

const serializer: Serializer<InvoiceTax> = [{ name: 'price', parse: 'number' }]
