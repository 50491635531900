import { proxy, useSnapshot } from 'valtio'

import { registerUnitStatus, type UnitStatusConfig } from './units/status/resource'

interface Resources extends UnitStatusConfig {}

export const resources = proxy<Resources>({
    unitStatus: null,
})

export const registerResources = () => {
    registerUnitStatus(resources)
}

export const useResources = (): Resources => {
    return useSnapshot(resources) as Resources
}
