import { type ReactElement } from 'react'

import { type ListControllerResult, useRecordContext, useShowContext } from 'react-admin'

import { useOpenUtilityDrawer, UtilityDrawerEditor } from 'components'
import { type ResourceType } from 'core'
import { renderOnPermission } from 'core/permissions'
import { JobLaborCreate, JobLaborForm } from 'pages/WorkOrders/Jobs/Labor'
import { type JobPartFormProps } from 'pages/WorkOrders/Jobs/Parts/JobPartForm'
import { type WorkOrderItemsExtra } from 'pages/WorkOrders/Jobs/config/constants'
import { LineItemTypeKeys } from 'resources/lineItems'
import { PartScanner } from 'resources/parts'
import {
    type JobPartModel,
    type WorkOrderModel,
    type JobModel,
    useIsVenderWo,
    useWoActionIsDisabled,
    woJobItemPermissions,
} from 'resources/workOrders'

import {
    jobPartApiContext,
    JobPartForm,
    jobPartSerializer,
    jobPartWithoutNumberSerializer,
} from '../../../../Parts'

import PartWithoutNumberDrawerForm from './PartWithoutNumberDrawerForm'
export interface LaborFormExtra extends WorkOrderItemsExtra {
    job: JobModel
    workOrder: WorkOrderModel
}

export interface PartWithoutNumberFormExtra
    extends Pick<WorkOrderItemsExtra, 'job' | 'disabledFields'> {
    record?: JobPartModel
}

export const PartCreateWithoutNumberDrawerToggler = renderOnPermission(
    ({
        children,
        resource,
        listContext,
    }: {
        children: (params: { onClick: () => void }) => ReactElement
        resource: ResourceType
        listContext: ListControllerResult
    }) => {
        const open = useOpenUtilityDrawer()
        const { record } = useShowContext<WorkOrderModel>()
        const disabled = useWoActionIsDisabled({ workOrder: record })
        const job = useRecordContext<JobModel>()
        const extra: PartWithoutNumberFormExtra = {
            disabledFields: disabled,
            job,
        }

        return children({
            onClick: disabled
                ? () => {
                      /* */
                  }
                : () => {
                      open({
                          extraArgs: {
                              listContext,
                              resource,
                          },
                          drawerArgs: {
                              title: 'Add Part (Without Part Number)',
                              renderWrapper: (params) => (
                                  <UtilityDrawerEditor
                                      {...params}
                                      serializer={jobPartWithoutNumberSerializer}
                                      defaultValues={{
                                          type: LineItemTypeKeys.PART,
                                      }}
                                  />
                              ),
                              renderContent: () => <PartWithoutNumberDrawerForm />,
                              renderBottomRight: (render) => render({ label: 'Add Part' }),
                          },
                          extra,
                      })
                  },
        })
    },
    woJobItemPermissions.addPart,
)
export const PartCreateDrawerToggler = renderOnPermission(
    ({
        title,
        children,
        resource,
        listContext,
        hideInputs,
    }: {
        children: (params: { onClick: () => void }) => ReactElement
        resource: ResourceType
        listContext: ListControllerResult
        title?: string
        hideInputs?: JobPartFormProps['hideInputs']
    }) => {
        const open = useOpenUtilityDrawer()
        const { record } = useShowContext<WorkOrderModel>()
        const disabled = useWoActionIsDisabled({ workOrder: record })
        const isVendorWo = useIsVenderWo()
        const job = useRecordContext<JobModel>()

        const extra: WorkOrderItemsExtra = {
            vendor: record.purchaseOrderData?.vendorData,
            job,
            workOrder: record,
        }

        return children({
            onClick: disabled
                ? () => {
                      /* */
                  }
                : () => {
                      open({
                          extraArgs: {
                              listContext,
                              resource,
                          },
                          drawerArgs: {
                              title: title || 'Add Part',
                              renderWrapper: (params) => (
                                  <UtilityDrawerEditor
                                      {...params}
                                      serializer={jobPartSerializer}
                                      defaultValues={{
                                          type: LineItemTypeKeys.PART,
                                      }}
                                  />
                              ),
                              renderContent: () => <JobPartForm hideInputs={hideInputs} />,
                              renderBottomRight: (render) => render({ label: 'Add Part' }),
                              renderTopRight: () =>
                                  isVendorWo ? null : (
                                      <PartScanner filter={jobPartApiContext(job.id)} />
                                  ),
                          },
                          extra,
                      })
                  },
        })
    },
    woJobItemPermissions.addPart,
)

export const LaborCreateDrawerToggler = renderOnPermission(
    ({
        children,
        resource,
        listContext,
        job,
    }: {
        children: (params: { onClick: () => void }) => ReactElement
        resource: ResourceType
        listContext: ListControllerResult
        job: JobModel
    }) => {
        const open = useOpenUtilityDrawer()
        const { record } = useShowContext()
        const isVendorWo = useIsVenderWo()

        const disabled = useWoActionIsDisabled({ workOrder: record })

        return children({
            onClick: disabled
                ? () => {
                      /* */
                  }
                : () => {
                      open({
                          extraArgs: {
                              listContext,
                              resource,
                          },
                          drawerArgs: {
                              title: 'Add Labor',
                              renderWrapper: (params) => (
                                  <JobLaborCreate
                                      {...params}
                                      isVendorWo={isVendorWo}
                                  />
                              ),
                              renderContent: () => <JobLaborForm />,
                              renderBottomRight: (render) => render({ label: 'Add Labor' }),
                          },
                          extra: {
                              job,
                              workOrder: record,
                          } as LaborFormExtra,
                      })
                  },
        })
    },
    woJobItemPermissions.addLabor,
)
