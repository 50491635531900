import { CheckboxInput, ColorInput, TextInput } from 'components'
import { requiredValidation, validateMaxLength } from 'core'
import { Alert } from 'ui'
const nameValidation = [requiredValidation, validateMaxLength(20)]

const UnitStatusForm = () => {
    return (
        <>
            <TextInput
                source="name"
                validate={nameValidation}
                label="Name"
            />

            <ColorInput
                required
                source="color"
                label="Color"
            />

            <CheckboxInput
                source="isDefault"
                label="Set as Default Unit Status"
            />

            <Alert severity="info">
                The status marked as 'default' will be pre-selected when adding new units, including
                those imported via integration.
            </Alert>
        </>
    )
}

export default UnitStatusForm
