import { type DeleteOneParams } from 'core'

import { type InvoiceModel } from '../types'

export const deleteInvoiceTitle = (plural?: boolean) =>
    `Are you sure you want to delete the selected Invoice${plural ? '(s)' : ''}?`

export const deleteInvoiceParams = (record: InvoiceModel): DeleteOneParams => ({
    confirmConfig: {
        title: deleteInvoiceTitle(),
        content: `This invoice contains ${record.itemsCount} line items.`,
    },
})
