import { useMemo } from 'react'

import { authStore } from 'core'
import { useFlags } from 'lib'

import { type NavItemConfig } from './types'

export const useMenu = (_menu: NavItemConfig[]) => {
    const flags = useFlags()
    const settings = authStore.companySettings
    const permissions = authStore.permissions
    return useMemo(() => getMenu(_menu, flags, settings, permissions), [settings, flags, _menu])
}

const getMenu = (items: NavItemConfig[], flags, settings, permissions): NavItemConfig[] => {
    return items
        .map((item) => {
            const _item = { ...item }
            if (item.show?.({ flags, settings, permissions }) === false) {
                return null
            }
            if (item.items) {
                const children = getMenu(item.items, flags, settings, permissions)
                if (!children?.length) {
                    return null
                }
                if (children.length === 1 && !children[0].items?.length) {
                    const onlyChild = children[0]

                    _item.path = onlyChild.path
                    _item.text = onlyChild.text
                    if (onlyChild.Icon) {
                        _item.Icon = onlyChild.Icon
                        _item.ActiveIcon = onlyChild.ActiveIcon
                    }

                    delete _item.items
                } else {
                    _item.items = children
                }
            }

            return _item
        })
        .filter(Boolean)
}
