import { type ReactElement } from 'react'

import { DistanceLabel, resolveIntegerSpacedMask } from 'components'
import { type IntervalsType, type PMIntervalTimeType, type PmModel } from 'resources/pm'
import { woFields } from 'resources/workOrders'
import { Stack, BoxContainer, Typography } from 'ui'

import AddWorkOrderMenu from '../../components/AddWorkOrderMenu'

const timeLeftFormat = (selectedPM: PMIntervalTimeType) => {
    const { years, months, days } = selectedPM.remainingTillOverduePretty
    const y = Math.abs(years)
    const m = Math.abs(months)
    const d = Math.abs(days)
    const pluralText = (time: number, text: string) =>
        time ? `${time} ${text}${time === 1 ? '' : 's'}` : ''
    return (
        [pluralText(y, 'year'), pluralText(m, 'month'), pluralText(d, 'day')]
            .filter(Boolean)
            .join(', ') || '0 days'
    )
}
export const getLeft = (selectedPM: IntervalsType) => {
    if (selectedPM.type === 'TIME') {
        return timeLeftFormat(selectedPM)
    }
    if (selectedPM.threshold !== null || selectedPM.status === 'OVERDUE') {
        return resolveIntegerSpacedMask(Math.abs(selectedPM.value - selectedPM.passed))
    }
    return `${selectedPM.value - selectedPM.passed} out of ${selectedPM.value}`
}
export const getLeftUnitType = (
    type: IntervalsType['type'],
    plural?: boolean,
): ReactElement | string => {
    if (type === 'TIME') {
        return 'Time'
    }
    if (type === 'ENGINE_HOURS' || type === 'APU_HOURS') {
        return 'Hours'
    }
    return (
        <DistanceLabel
            plural={plural}
            textCase="capital"
        />
    )
}

const BottomSection = ({
    selectedPM,
    pmReminder,
    isArchived,
}: {
    selectedPM: IntervalsType
    pmReminder: PmModel
    isArchived: boolean
}) => {
    const getLeftUnitTypeValue = getLeftUnitType(selectedPM.type, true)
    const leftValue = getLeft(selectedPM)
    const workOrderContent = pmReminder.workOrderData ? (
        <Typography variant="body2">{woFields.self.linkValue(pmReminder.workOrderData)}</Typography>
    ) : (
        <AddWorkOrderMenu<PmModel>
            options={{
                jobDefaultValues: {
                    notes: pmReminder.description
                        ? [
                              {
                                  createdByData: { name: pmReminder.name },
                                  source: 'PM_SCHEDULE',
                                  text: pmReminder.description,
                              },
                          ]
                        : [],
                },
                woDisabledFields: {
                    unit: true,
                },
            }}
            disabled={isArchived}
            parentRecord={pmReminder}
        />
    )
    return (
        <Stack
            spacing="10px"
            sx={{ paddingTop: '12px' }}
        >
            <BoxContainer justifyContent="space-between">
                <Typography
                    variant="tooltip"
                    color="text.disabled"
                    textTransform="uppercase"
                >
                    LEFT ({getLeftUnitTypeValue})
                </Typography>
                <BoxContainer overflow="hidden">
                    <Typography
                        textOverflow="ellipsis"
                        overflow="hidden"
                        whiteSpace="nowrap"
                        variant="body2"
                        color={
                            isArchived
                                ? 'text.primary'
                                : selectedPM.status === 'OVERDUE'
                                  ? 'error.main'
                                  : 'text.primary'
                        }
                    >
                        {leftValue}
                    </Typography>

                    {leftValue.charAt(0) !== '0' && selectedPM.status === 'OVERDUE' && (
                        <Typography
                            variant="body2"
                            color={isArchived ? 'text.primary' : 'error.main'}
                        >
                            &nbsp;overdue
                        </Typography>
                    )}
                </BoxContainer>
            </BoxContainer>
            <BoxContainer justifyContent="space-between">
                <Typography
                    variant="tooltip"
                    color="text.disabled"
                    textTransform="uppercase"
                >
                    INTERVAL (
                    {selectedPM.type === 'TIME' ? selectedPM.valueType + 'S' : getLeftUnitTypeValue}
                    )
                </Typography>
                <Typography
                    variant="body2"
                    color="text.primary"
                >
                    {resolveIntegerSpacedMask(selectedPM.value)}
                </Typography>
            </BoxContainer>
            <BoxContainer justifyContent="space-between">
                <Typography
                    variant="tooltip"
                    color="text.disabled"
                >
                    WORK ORDER
                </Typography>
                {workOrderContent}
            </BoxContainer>
        </Stack>
    )
}
export default BottomSection
