import { type ReactElement } from 'react'

import { reaction } from 'mobx'

import { type NoInfer } from 'appTypes'
import { type Column, type ListFilterChoice, ListFilterValueInput } from 'components'
import { authStore } from 'core'

import { type UnitModel } from '../types'

import { StatusField } from './components'
import { type UnitStatusModel } from './types'

const source = 'status' as const satisfies keyof UnitModel

const statusLabel = 'Status'
const statusLongName = 'Unit Status'

const value = (status: UnitStatusModel, selector?: boolean): ReactElement => (
    <StatusField
        record={status}
        selector={selector}
    />
)
const valueByParent = <T extends Pick<UnitModel, 'statusData'>>(parent: T, selector?: boolean) =>
    value(parent?.statusData, selector)

const tableColumn = ({
    label,
    render,
}: {
    label?: string
    render: (data: unknown) => ReactElement
}) =>
    ({
        field: source,
        headerName: label || statusLabel,
        renderCell: ({ row }) => {
            return render(row)
        },
    }) as const satisfies Column

const sort = ({ label: labelProp }: { label?: string } = {}) =>
    ({
        id: source,
        label: labelProp || statusLabel,
    }) as const

const filter = <Source extends string = typeof source>({
    id,
    label: labelProp,
}: {
    id?: Source
    label?: string
} = {}) =>
    ({
        id: (id || source) as NoInfer<Source>,
        label: labelProp || statusLabel,
        renderComponent: (props) => (
            <ListFilterValueInput
                {...props}
                inputText={(option) => option.id}
                makeItemLabel={(record) => {
                    return value(record as UnitStatusModel)
                }}
            />
        ),
    }) as const satisfies ListFilterChoice

const status = {
    source,
    label: statusLabel,
    longLabel: statusLongName,
    value,
    valueByParent,
    sort,
    filter,
    tableColumn,
    show: true,
}

const noStatuses: typeof status = {
    ...status,
    value: () => null,
    valueByParent: () => null,
    tableColumn: () => null,
    filter: () => null,
    sort: () => null,
    show: false,
}

export type UnitStatusConfig = {
    unitStatus: typeof status
}

const getStatus = (hasUnitStatuses: boolean): typeof status => {
    if (hasUnitStatuses) {
        return status
    }

    return noStatuses
}

export const registerUnitStatus = (resources) => {
    reaction(
        () => authStore.companySettings.hasUnitStatuses,
        (hasUnitStatuses) => {
            resources.unitStatus = getStatus(hasUnitStatuses)
        },
    )
}
