import { type FC } from 'react'

import { inject, observer } from 'mobx-react'

import Icons from 'assets/icons'
import {
    List,
    ListAvatar,
    ListBase,
    type CardListConfig,
    ListFilterValueInput,
    type FilterConfig,
    ListFilterDateRangeValueInput,
    type ListBulkActions,
    type ListSortContentProps,
    type DatagridColumnsProps,
    datagridAvatarColumn,
} from 'components'
import { type SortPayload } from 'core'
import {
    type ActionChildren,
    deleteManyFromListAction,
    deleteOneAction,
    editRedirectInListAction,
    multiselectAction,
} from 'core/actions'
import { type AuthStore } from 'core/auth'
import { useCreateResourcePath } from 'core/resource'
import { formatDate } from 'lib'
import {
    formatPOTotal,
    getPOIcon,
    poFields,
    poResource,
    type PoStatusKeys,
    type PurchaseOrderModel,
} from 'resources/purchaseOrders'
import { shopFields } from 'resources/shops'
import { woResource } from 'resources/workOrders'
import { PageContent, Typography } from 'ui'
import { displayBooleanValue } from 'utils'

import POExportPDF from '../Show/components/POExportPDF'
import { PurchaseOrderDrawerToggler } from '../components'
import { deletePOTitle, getDisableDeleteTitle } from '../utils'

import {
    InvoiceField,
    PoListHeader,
    PurchaseOrderTitle,
    WOPurchaseOrderEditButton,
} from './components'

const defaultSort: SortPayload<PurchaseOrderModel> = {
    field: 'created',
    order: 'DESC',
}

const bulkActions: ListBulkActions = ({ children }) => [
    deleteManyFromListAction({
        children,
        confirmConfig: {
            title: 'Are you sure you want to delete the selected Purchase Orders?',
        },
    }),
]

const actions = (
    record: PurchaseOrderModel,
    children: ActionChildren,
    cardActions?: boolean,
    hasInventory?: boolean,
) => {
    const disableDeleteTitle = getDisableDeleteTitle(record)
    const actions = [
        record.workOrder ? (
            <WOPurchaseOrderEditButton
                record={record}
                key="edit"
            >
                {children({
                    Icon: Icons.Edit,
                    title: 'View/Edit',
                })}
            </WOPurchaseOrderEditButton>
        ) : (
            editRedirectInListAction({
                children,
                id: record.id,
            })
        ),
        <POExportPDF
            key="pdf"
            record={record}
        >
            {(open) =>
                children({
                    onClick: open,
                    title: 'Export PDF',
                    Icon: Icons.Pdf,
                    ...(cardActions && { disableCloseOnClick: true }),
                })
            }
        </POExportPDF>,
        deleteOneAction({
            children: (params) =>
                children({
                    ...params,
                }),
            id: record.id,
            disabled: Boolean(disableDeleteTitle),
            titleOnDisabled: disableDeleteTitle,
            confirmConfig: {
                title: deletePOTitle,
            },
        }),
    ]
    if (cardActions && hasInventory) {
        return [
            actions[0],
            multiselectAction({
                children,
                id: record.id,
            }),
            ...actions.slice(1),
        ]
    }
    return actions
}

const PurchaseOrdersList: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        const createPath = useCreateResourcePath()

        const navigateTo = (record: PurchaseOrderModel) => {
            return record.workOrder
                ? createPath({
                      resource: woResource.resource,
                      type: 'edit',
                      id: record.workOrder,
                  }) + '/invoice'
                : createPath({
                      resource: poResource.resource,
                      type: 'edit',
                      id: record.id,
                  })
        }

        const { filtersConfig, sortConfig, columnsConfig, cardsConfig } = getPoConfigs(
            auth,
            navigateTo,
        )

        return (
            <ListBase sort={defaultSort}>
                <PoListHeader />
                <PageContent>
                    <List
                        sortCfg={sortConfig}
                        columnsCfg={columnsConfig}
                        cardsCfg={cardsConfig}
                        filtersCfg={filtersConfig}
                        bulkActions={bulkActions}
                        listFTUProps={{
                            secondaryTitle: 'Would you like to create one?',
                            linkText: (
                                <PurchaseOrderDrawerToggler>
                                    {({ onClick }) => (
                                        <Typography
                                            variant="body1"
                                            onClick={onClick}
                                            color={(theme) => theme.palette.primary.main}
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            Create Purchase Order{' '}
                                        </Typography>
                                    )}
                                </PurchaseOrderDrawerToggler>
                            ),
                            linkAction: (e) => {
                                e.preventDefault()
                            },
                        }}
                    />
                </PageContent>
            </ListBase>
        )
    }),
)

export default PurchaseOrdersList

export const getPoConfigs = (
    auth: AuthStore,
    navigateTo: (record: PurchaseOrderModel) => string,
) => {
    const hasInventory = auth.companySettings.hasInventory
    const filtersConfig: FilterConfig<PurchaseOrderModel & { invoice: never }> = {
        filters: [
            {
                id: 'number',
                label: 'PO Number',
            },
            shopFields.self.filter({
                withShopContext: auth,
            }),
            hasInventory
                ? {
                      id: 'type',
                      label: 'PO Type',
                      renderComponent: (props) => (
                          <ListFilterValueInput
                              {...props}
                              inputText={(option) => option.id}
                              makeItemLabel={(record) => poFields.type.config[record.id]}
                          />
                      ),
                  }
                : null,
            { id: 'vendor', label: 'Vendor' },
            {
                id: 'status',
                label: 'Status',
                renderComponent: (props) => (
                    <ListFilterValueInput
                        {...props}
                        inputText={(option) => option.id}
                        makeItemLabel={(record) =>
                            poFields.status.value({ status: record.id as PoStatusKeys })
                        }
                    />
                ),
            },
            {
                id: 'created',
                label: 'Created on',
                filterType: 'range',
                renderComponent: (props) => <ListFilterDateRangeValueInput {...props} />,
            },
            hasInventory
                ? {
                      id: 'dateLastReceived',
                      label: 'Last Received on',
                      filterType: 'range',
                      renderComponent: (props) => <ListFilterDateRangeValueInput {...props} />,
                  }
                : null,
            {
                id: 'closedOn',
                label: 'Closed on',
                filterType: 'range',
                renderComponent: (props) => <ListFilterDateRangeValueInput {...props} />,
            },
            hasInventory
                ? {
                      id: 'backorder',
                      label: 'Backorder',
                  }
                : null,
            {
                id: 'invoice',
                label: 'Invoice Number',
            },
        ],
    }

    const sortConfig: ListSortContentProps<PurchaseOrderModel & { invoice: never }> = {
        sortBy: [
            { id: 'number', label: 'PO Number' },
            shopFields.self.sort({
                withShopContext: auth,
            }),
            hasInventory ? { id: 'type', label: 'PO Type' } : null,
            { id: 'vendor', label: 'Vendor' },
            {
                id: 'status',
                label: 'Status',
            },
            {
                id: 'created',
                label: 'Created on',
            },
            hasInventory ? { id: 'dateLastReceived', label: 'Last Received on' } : null,
            { id: 'closedOn', label: 'Closed on' },
            { id: 'items', label: 'Items' },
            hasInventory ? { id: 'backorder', label: 'Backorder' } : null,
            { id: 'invoice', label: 'Invoices Count' },
            {
                id: 'total',
                label: 'Total Purchase',
            },
        ],
    }

    const columnsConfig: DatagridColumnsProps<
        PurchaseOrderModel & { photo: never; invoice: never }
    > = {
        mainField: 'number',
        checkboxSelection: hasInventory,
        avatarSource: 'photo',
        columns: [
            datagridAvatarColumn({
                field: 'photo',
                headerName: 'Avatar',
                avatar: (record) => {
                    const Icon = getPOIcon(record.type)

                    return (
                        <ListAvatar
                            linkProps={{
                                'aria-label': `View Purchase Order with unit number ${record.number}`,
                            }}
                            id={record.id}
                            imageSrc={record.photo}
                            defaultImage={<Icon />}
                            customPath={navigateTo(record)}
                        />
                    )
                },
            }),
            {
                field: 'number',
                headerName: 'PO Number',
                renderCell: ({ row }) => <PurchaseOrderTitle record={row} />,
            },
            shopFields.self.tableColumn({
                dataToRecord: (data: PurchaseOrderModel) => data.shopData,
                withShopContext: auth,
            }),
            hasInventory
                ? {
                      field: 'type',
                      headerName: 'PO Type',
                      valueFormatter: ({ value }) => poFields.type.config[value],
                  }
                : null,
            {
                field: 'vendor',
                headerName: 'Vendor',
                valueGetter: ({ row }) => row.vendorData.name,
            },
            {
                field: 'status',
                headerName: 'Status',
                renderCell: ({ row }) => poFields.status.value(row),
            },
            {
                field: 'created',
                headerName: 'Created on',
                valueFormatter: ({ value }) =>
                    formatDate(value, (dateFormats) => dateFormats.shortenedDateTime),
            },
            hasInventory
                ? {
                      field: 'dateLastReceived',
                      headerName: 'Last Received on',
                      valueFormatter: ({ value }) =>
                          formatDate(value, (dateFormats) => dateFormats.shortenedDateTime),
                  }
                : null,
            {
                field: 'closedOn',
                headerName: 'Closed on',
                valueFormatter: ({ value }) =>
                    formatDate(value, (dateFormats) => dateFormats.shortenedDateTime),
            },
            { field: 'items', headerName: 'Items' },
            hasInventory
                ? {
                      field: 'backorder',
                      headerName: 'Backorder',
                      valueFormatter: ({ value }) => displayBooleanValue(value),
                  }
                : null,
            {
                field: 'invoice',
                headerName: 'Invoice Number',
                renderCell: ({ row }) =>
                    row.invoiceData.length ? <InvoiceField invoices={row.invoiceData} /> : null,
            },
            {
                field: 'total',
                headerName: 'Total Purchase',
                align: 'right',
                renderCell: ({ value }) => {
                    return formatPOTotal(value)
                },
            },
        ],
        actions: ({ row }, { children }) => actions(row, children),
    }

    const cardsConfig: CardListConfig<PurchaseOrderModel> = {
        titleSource: (record) => <PurchaseOrderTitle record={record} />,
        disableTitleLink: true,
        defaultImage: (record) => {
            const Icon = getPOIcon(record.type)

            return <Icon />
        },
        subTitleSource: (record) => poFields.status.value(record),
        details: [
            { label: 'vendor', source: 'vendorData', render: (value) => value.name },
            shopFields.self.dataCardRow({
                dataToRecord: (data: PurchaseOrderModel) => data.shopData,
                withShopContext: auth,
            }),
            hasInventory
                ? {
                      label: 'PO Type',
                      source: 'type',
                      render: (value) => poFields.type.config[value],
                  }
                : null,
            {
                label: 'Created on',
                source: 'created',
                render: (value) =>
                    formatDate(value, (dateFormats) => dateFormats.shortenedDateTime),
            },
            hasInventory
                ? {
                      label: 'Last Received on',
                      source: 'dateLastReceived',
                      render: (value) =>
                          formatDate(value, (dateFormats) => dateFormats.shortenedDateTime),
                  }
                : null,
            {
                label: 'Closed on',
                source: 'closedOn',
                render: (value) =>
                    formatDate(value, (dateFormats) => dateFormats.shortenedDateTime),
            },
            { label: 'Items', source: 'items' },
            hasInventory
                ? { label: 'Backorder', source: 'backorder', render: displayBooleanValue }
                : null,
            {
                label: 'Invoice Number',
                source: 'invoiceData',
                render: (value) =>
                    value.length ? (
                        <InvoiceField
                            invoices={value}
                            togglerSize="extraSmall"
                        />
                    ) : null,
            },
            {
                label: 'Total Purchase',
                source: 'total',
                render: (value) => {
                    return formatPOTotal(value)
                },
            },
        ],
        actions: (record, { children }) => actions(record, children, true, hasInventory),
    }

    return { filtersConfig, sortConfig, columnsConfig, cardsConfig }
}
