import { type FC } from 'react'

import { useWatch } from 'react-hook-form'

import { inputIntegerNonNegativeSpacedMaskParams, TextInput } from 'components'
import { requiredValidation } from 'core'
import { type LastDoneHelperTextProps, LastDoneHelperTextBase } from 'resources/pm'
import { getMeterTypeAdornment, meterTypesConfig, type MeterModel } from 'resources/units'
import { inputLabelClasses, Typography, StyledElement } from 'ui'
import { camelCase } from 'utils'

import { type MeterType } from './CloseFields'

interface WOResetPMHelperTextProps extends Omit<LastDoneHelperTextProps, 'lastDone' | 'meters'> {
    name: string
}

const WOResetPMHelperText: FC<WOResetPMHelperTextProps> = ({ name, ...props }) => {
    const lastDone = useWatch({ name })
    return (
        <LastDoneHelperTextBase
            {...props}
            lastDone={lastDone}
        />
    )
}

const pmMeterInputSource = (type) => `${camelCase(type)}Value`
const meterTypes: MeterModel['type'][] = ['ENGINE_HOURS', 'HUBOMETER', 'ODOMETER', 'APU_HOURS']
export const pmMeterSources = meterTypes.map(pmMeterInputSource)

const PmMeterInputs: FC<{ meter: MeterType }> = ({ meter }) => {
    const meterPms = useWatch({ name: meter.pmIds.map((id) => `pm.${id}`) })

    const disabled = meterPms.every((pm) => pm === false)
    const name = pmMeterInputSource(meter.type)

    return (
        <StyledElement key={meter.id}>
            <Typography
                variant="subtitle2"
                mb="11px"
            >
                {meterTypesConfig[meter.type]?.name}
            </Typography>
            <TextInput
                disabled={disabled}
                source={name}
                sx={(theme) => ({
                    [`& .${inputLabelClasses.root} span`]: {
                        color: disabled
                            ? `${theme.palette.text.disabled} !important`
                            : theme.palette.text.primary,
                    },
                })}
                helperText={
                    meter.value ? (
                        <WOResetPMHelperText
                            meter={meter as MeterModel}
                            meterType={meter.type}
                            value={meter.value}
                            name={name}
                        />
                    ) : null
                }
                label="Reading at Completion Date"
                validate={disabled ? undefined : requiredValidation}
                InputProps={{
                    endAdornment: getMeterTypeAdornment(meter.type),
                }}
                {...inputIntegerNonNegativeSpacedMaskParams}
            />
        </StyledElement>
    )
}

export default PmMeterInputs
