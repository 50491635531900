import { type FC, type ReactElement } from 'react'

import { useCreatePath } from 'react-admin'

import { type Identifier } from 'appTypes'
import { useOpenUtilityDrawer, UtilityDrawerEditor } from 'components'
import { NotificationMessage } from 'core'
import { authStore } from 'core/auth'
import { basePermissions, renderOnPermission } from 'core/permissions'
import { customerFields } from 'resources/customers'
import { unitFields } from 'resources/units'
import { useWoLimit, woResource, type WorkOrderModel } from 'resources/workOrders'

import { workOrderSerializer } from '../config/constants'

import WorkOrderForm, { type WorkOrderFormProps } from './WorkOrderForm'

interface WorkOrderEditDrawerTogglerProps {
    children: (params: { onClick: () => void }) => ReactElement
    id?: Identifier
    defaultValues?: { [key in keyof WorkOrderModel & { domicile?: string }]?: WorkOrderModel[key] }
    disabledFields?: WorkOrderFormProps['disabledFields']
    createFormConfig?: WorkOrderFormProps['createFormConfig']
    hiddenFields?: WorkOrderFormProps['hiddenFields']
}

export const WorkOrderDrawerToggler: FC<WorkOrderEditDrawerTogglerProps> = renderOnPermission(
    ({
        children,
        id,
        defaultValues,
        disabledFields,
        createFormConfig,
        hiddenFields,
    }: WorkOrderEditDrawerTogglerProps) => {
        const open = useOpenUtilityDrawer()
        const { hasError, woLimitReachedAction } = useWoLimit()
        const createPath = useCreatePath()
        return children({
            onClick: () => {
                open({
                    extraArgs: {
                        id,
                        resource: woResource,
                    },
                    drawerArgs: {
                        title: id ? 'Edit Details' : 'Create Work Order',
                        renderWrapper: (params) => (
                            <UtilityDrawerEditor
                                formOnError={({ errors, defaultOnError, reset }) => {
                                    if (hasError(errors)) {
                                        woLimitReachedAction()
                                        reset(
                                            {},
                                            {
                                                keepValues: true,
                                                keepDirty: true,
                                                keepTouched: true,
                                            },
                                        )
                                        return
                                    }

                                    defaultOnError()
                                }}
                                {...params}
                                defaultValues={(record) => {
                                    if (!record) {
                                        if (authStore.shopId) {
                                            return { ...defaultValues, shop: authStore.shopId }
                                        }
                                        return defaultValues
                                    }
                                    return {
                                        [customerFields.poNumber.source]:
                                            record.salesOrderData?.poNumber,
                                        [customerFields.self.source]: record.unitData.customer,
                                        [unitFields.domicile.source]: record.unitData.domicile,
                                    }
                                }}
                                serializer={workOrderSerializer}
                                successMessage={({ defaultMessages, response }) => {
                                    if (id) {
                                        return defaultMessages.updated
                                    }

                                    const wo = response as WorkOrderModel

                                    return {
                                        title: defaultMessages.created,
                                        message: (
                                            <NotificationMessage.Navigate
                                                to={createPath({
                                                    resource: woResource.resource,
                                                    type: 'edit',
                                                    id: wo.id,
                                                })}
                                            >
                                                {wo.number}
                                            </NotificationMessage.Navigate>
                                        ),
                                    }
                                }}
                            />
                        ),
                        renderContent: () => (
                            <WorkOrderForm
                                disabledFields={{
                                    shop: id && true,
                                    unit: id && true,
                                    vendor: id && true,
                                    ...disabledFields,
                                }}
                                createFormConfig={createFormConfig}
                                hiddenFields={hiddenFields}
                            />
                        ),
                    },
                })
            },
        })
    },
    basePermissions.update && basePermissions.create,
)

export default WorkOrderDrawerToggler
