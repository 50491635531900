import { useRef, type FC } from 'react'

import { useListController } from 'core'
import { globalClassNames } from 'lib'
import { useResources } from 'resources'
import { unitStatusResource, type UnitStatusModel } from 'resources/units'
import { Anchor, Box, BoxContainer, SimpleScrollbar, Stack, Typography } from 'ui'

import { WidgetBaseContainer, WidgetListContainer, WidgetTitle } from '../../WidgetBase'
import { WidgetSkeleton } from '../../WidgetBase/WidgetBase'

const UnitStatusesWidget = () => {
    const listController = useListController<UnitStatusModel>({
        resource: unitStatusResource.resource,
        perPage: 1000,
    })
    const data = listController.data

    const widgetRef = useRef<HTMLDivElement>()

    if (!data) {
        return <WidgetSkeleton />
    }

    return (
        <WidgetBaseContainer ref={widgetRef}>
            <Stack
                p="20px 20px 0px 20px"
                boxSizing="border-box"
                height="100%"
                direction="column"
            >
                <WidgetTitle paddingBottom="11px">Unit Statuses</WidgetTitle>
                <Box
                    overflow="hidden"
                    pb="20px"
                >
                    <StatusesList data={data} />
                </Box>
            </Stack>
        </WidgetBaseContainer>
    )
}
export default UnitStatusesWidget

const StatusesList: FC<{ data: UnitStatusModel[] }> = ({ data }) => {
    const { unitStatus } = useResources()

    return (
        <SimpleScrollbar sx={{ height: '100%' }}>
            <WidgetListContainer sx={{ gap: '8px' }}>
                {data.map((status) => {
                    return (
                        <Typography
                            component={Stack}
                            key={status.id}
                            direction="row"
                            variant="chartsBody"
                            justifyContent="space-between"
                        >
                            <BoxContainer
                                sx={{
                                    pr: '10px',
                                }}
                                className={globalClassNames.ellipsis}
                            >
                                {unitStatus.value(status)}
                            </BoxContainer>
                            <Anchor>{status.usage}</Anchor>
                        </Typography>
                    )
                })}
            </WidgetListContainer>
        </SimpleScrollbar>
    )
}
