import { createTheme as createBaseTheme, type Breakpoint, type ThemeOptions } from '@mui/material'

import { type ThemeOptionsConfig } from 'lib'

import lightPalette, { type IPalette } from './palette'
import themeTypography, { type ITypography } from './typography'

declare module '@mui/material/styles' {
    interface Palette extends IPalette {}
    interface PaletteOptions extends IPalette {}

    interface PaletteColor extends Partial<IPalette> {}
    interface SimplePaletteColorOptions extends Partial<IPalette> {}
    interface Theme {
        props: typeof extraProps

        palette: IPalette

        typography: ITypography
    }

    interface ThemeOptions extends Partial<Theme> {}
}

const extraProps = {
    drawerWidth: 80,
    headerHeight: 56,
    sidebarPaddingX: 8,
    mobileViewBreakpoint: 'md' as const satisfies Breakpoint,
}

export type BreakpointsValue = {
    [key in Breakpoint]: number
}

export const themeBreakpoints: BreakpointsValue = {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
}

export const themeOption: ThemeOptionsConfig = {
    palette: lightPalette,
}

export interface CreateThemeConfig {
    components: ThemeOptions['components']
    breakpoints?: BreakpointsValue
    props?: Partial<typeof extraProps>
}

export const createTheme = ({
    components,
    breakpoints = themeBreakpoints,
    props,
}: CreateThemeConfig) => {
    const typography = themeTypography(themeOption)

    const rtheme = createBaseTheme({
        direction: 'ltr',
        palette: lightPalette,
        typography,
        props: { ...extraProps, ...props },
        spacing: 4,
        shape: {
            borderRadius: 4,
        },
        components,
        breakpoints: {
            values: breakpoints,
        },
    })

    return rtheme
}

export const cssVariables = {
    pageContentPadding: '--page-content-padding',
    gap: '--gap',
    iconSize: '--icon-size',
}

export const globalClassNames = {
    ellipsis: 'ellipsis',
    autoRow: 'auto-row',
    withGap: 'with-gap',
    displayNone: 'd-none',
    noWrap: 'no-wrap',
    noShrink: 'no-shrink',
}

export const formHelperTextHeight = '39px'
