import { type BaseModel } from 'appTypes'
import {
    type LineItemTypeKeys,
    type LineItemLaborModel,
    type LineItemPartModel,
    type LineItemServiceModel,
    type LineItemTaxModel,
    type LineItemFeeModel,
} from 'resources/lineItems'
import { type WithUOM } from 'resources/unitsOfMeasure'

export enum LineItemOriginKeys {
    DIRECT = 'DIRECT',
    WORK_ORDER = 'WORK_ORDER',
    HYBRID = 'HYBRID',
}

export type InvoiceItemKeys = Extract<
    LineItemTypeKeys,
    LineItemTypeKeys.PART | LineItemTypeKeys.LABOR | LineItemTypeKeys.SERVICE
>
export type InvoiceItemModels = {
    [LineItemTypeKeys.LABOR]: InvoiceLabor
    [LineItemTypeKeys.PART]: InvoicePartModel
    [LineItemTypeKeys.SERVICE]: InvoiceService
}

export type InvoiceItem = InvoiceItemModels[keyof InvoiceItemModels]

export interface InvoicePartModel extends BaseModel, WithUOM, LineItemPartModel {
    fee: number
    tax: number
    orderQuantity: number
    orderPrice: number
    origin: LineItemOriginKeys
}
export interface InvoiceService extends LineItemServiceModel {
    fee: number
    tax: number
    orderQuantity: number
    orderPrice: number
    origin: LineItemOriginKeys
}

export interface InvoiceLabor extends LineItemLaborModel {
    fee: number
    origin: LineItemOriginKeys
    tax: number
    orderQuantity: number
    orderPrice: number
}

export interface InvoiceTax extends Omit<LineItemTaxModel, 'description'> {
    pricingName: string
    origin: LineItemOriginKeys
    pricingIsTaxExempt: boolean
    pricingTaxExemptNumber: string
}

export interface InvoiceFee extends Omit<LineItemFeeModel, 'description'> {
    pricingName: string
    origin: LineItemOriginKeys
}
