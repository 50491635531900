import defaultMessages from 'ra-language-english'
import { type I18nProvider } from 'react-admin'

import { setProperty, getProperty } from 'utils'

const messages = {
    ...defaultMessages,
    ENGINE_HOURS: 'Engine Hours',
    APU_HOURS: 'APU Hours',
}

setProperty(
    messages,
    'ra.message.unsaved_changes',
    "The changes you've made will be lost, are you sure you want to continue?",
)
setProperty(messages, 'ra.message.invalid_form', 'There was a validation error.')

const locale = 'en'

const i18nProvider: I18nProvider = {
    translate: (key) => getProperty(messages, key) || key,
    changeLocale: () => {
        // change here message/locale if needed
        return Promise.resolve()
    },
    getLocale: () => locale,
}

export default i18nProvider
