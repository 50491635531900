import { type FC } from 'react'

import { inject, observer } from 'mobx-react'
import { useRecordContext } from 'react-admin'

import { ListBase } from 'components'
import { HEAVY_HAUL_SOLUTIONS_ID } from 'configs'
import { useArchivedContext } from 'context'
import { type AuthStore, type SortPayload } from 'core'
import {
    unitAspects,
    type UnitModel,
    unitNumberSort,
    unitResource,
    UnitsListBase,
} from 'resources/units'
import { displayBooleanValue } from 'utils'

import Header from './Header'

export const CustomerUnitsList: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        const record = useRecordContext()
        const { isArchived } = useArchivedContext()

        const defaultSort =
            auth.currentCompanyId === HEAVY_HAUL_SOLUTIONS_ID ? unitNumberSort : createdSort

        return (
            <ListBase
                key={String(isArchived)}
                isLoading={!record}
                sort={defaultSort}
                resource={unitResource.resource}
                filter={{
                    withAspects: [unitAspects.costPerMeter, unitAspects.totals],
                    customer: record?.name,
                    archived: displayBooleanValue(isArchived),
                }}
                preferencesName={getPreferencesName(isArchived)}
            >
                <Header isArchived={isArchived} />
                <UnitsListBase
                    removeFilters
                    disableExportButton
                />
            </ListBase>
        )
    }),
)

const createdSort: SortPayload<UnitModel> = {
    field: 'created',
    order: 'DESC',
}

const getPreferencesName = (isArchived: boolean): string =>
    `customer-units${isArchived ? '-archived' : ''}`
