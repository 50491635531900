import { formatPercent } from 'components'
import { globalClassNames } from 'lib'
import { WidgetListContainer } from 'pages/Dashboard/components/WidgetBase/styled'
import { BoxContainer, Status, Typography } from 'ui'

import { ListItem, WidgetListDescription } from '../../components'
import { type ComplianceRateResponse } from '../types'
interface ComplianceRateListProps {
    validData: boolean
    description: string
    data: ComplianceRateResponse
}

const ComplianceRateList = ({ data, validData, description }: ComplianceRateListProps) => {
    if (!validData) {
        return <WidgetListDescription>{description}</WidgetListDescription>
    }
    return (
        <WidgetListContainer>
            <Typography
                component={ListItem}
                variant="chartListItem"
            >
                <BoxContainer>
                    <Status
                        iconColor={(theme) => theme.palette.charts.greenBody}
                        mr="13px"
                    />
                    On-Time (Planned + Due Soon)
                </BoxContainer>
                {formatPercent(data.onTimePercent)}
            </Typography>
            <Typography
                component={ListItem}
                variant="chartListItem"
            >
                <BoxContainer>
                    <Status
                        iconColor={(theme) => theme.palette.charts.red}
                        mr="13px"
                    />
                    Not On-Time (Overdue)
                </BoxContainer>
                <span className={globalClassNames.noWrap}>
                    {formatPercent(data.notOnTimePercent)}
                </span>
            </Typography>
        </WidgetListContainer>
    )
}
export default ComplianceRateList
