import Icons from 'assets/icons'
import { formatPercent, formatMoney } from 'components'
import { useCreateResourcePath } from 'core'
import { WidgetListContainer } from 'pages/Dashboard/components/WidgetBase/styled'
import LineItemsTooltip from 'pages/WorkOrders/Jobs/WorkOrderJobCard/components/LineItemsTooltip'
import { unitResource } from 'resources/units'
import { Typography } from 'ui'

import { WidgetListDescription, WidgetListRowBase } from '../../components'
import { type CostPerUnitList as CostPerUnitListType } from '../types'

interface CostPerUnitListProps {
    data: CostPerUnitListType
    description: string
    validData: boolean
}
const CostPerUnitList = ({ data = [], description, validData }: CostPerUnitListProps) => {
    const createPath = useCreateResourcePath()
    const list = data
        .slice(0, 3)
        .flatMap(({ number, total, totalPercent, id, archived, ...ratioData }) => {
            if (!total && total !== 0) {
                return []
            }
            const unitEditUrl = createPath({
                resource: unitResource.resource,
                id,
                type: 'edit',
            })
            return (
                <WidgetListRowBase
                    key={id}
                    link={unitEditUrl}
                    label={number}
                    hideClarification={!validData || total <= 0}
                    icon={(render) =>
                        archived ? render({ icon: Icons.Archived, tooltip: 'Archived' }) : undefined
                    }
                    clarification={
                        <>
                            {formatPercent(totalPercent)}{' '}
                            <Typography
                                color="text.disabled"
                                variant="chartListItem"
                            >
                                of total cost
                            </Typography>
                        </>
                    }
                    total={
                        <LineItemsTooltip data={ratioData}>{formatMoney(total)}</LineItemsTooltip>
                    }
                />
            )
        })

    if (!list.length) {
        return <WidgetListDescription>{description}</WidgetListDescription>
    }

    return <WidgetListContainer>{list}</WidgetListContainer>
}
export default CostPerUnitList
