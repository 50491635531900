import { type NoInfer } from 'appTypes'
import {
    type ListFilterChoice,
    type Column,
    type SortBy,
    ListFilterDateRangeValueInput,
    type DataListDetails,
} from 'components'
import { formatDate } from 'lib'

const source = 'created' as const
const label = 'Created on'

const value = (data: string | { created: string }) =>
    formatDate(
        typeof data === 'object' ? data.created : data,
        (dateFormats) => dateFormats.shortenedDateTime,
    )

const sort = <Source extends string = typeof source>({
    id,
    label: labelProp,
}: { id?: Source; label?: string } = {}) =>
    ({
        id: (id || source) as NoInfer<Source>,
        label: labelProp || label,
    }) as const satisfies SortBy

const filter = () =>
    ({
        id: source,
        label,
        filterType: 'range',
        renderComponent: (props) => <ListFilterDateRangeValueInput {...props} />,
    }) as const satisfies ListFilterChoice

const tableColumn = <Source extends string = typeof source>({
    id,
    label: labelProp,
    dataToValue,
}: {
    id?: Source
    label?: string
    dataToValue: (data) => string
}) =>
    ({
        field: (id || source) as NoInfer<Source>,
        headerName: labelProp || label,
        renderCell: ({ row }) =>
            formatDate(dataToValue(row), (dateFormats) => dateFormats.shortenedDateTime),
    }) as const satisfies Column

const dataCardRow = <Source extends string = typeof source>({
    id,
    label: labelProp,
    dataToValue,
}: {
    id?: Source
    label?: string
    dataToValue: (data) => string
}) =>
    ({
        source: (id || source) as NoInfer<Source>,
        label: labelProp || label,
        render: (_, data) =>
            formatDate(dataToValue(data), (dateFormats) => dateFormats.shortenedDateTime),
    }) as const satisfies DataListDetails<any, any>

export const createdField = {
    source,
    label,
    value,
    sort,
    tableColumn,
    dataCardRow,
    filter,
}

export interface WithCreatedField {
    created: string
}
