import { GlobalStyles } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LicenseInfo } from '@mui/x-license'
import { Provider as MobxProvider } from 'mobx-react'
import PWAPrompt from 'react-ios-pwa-prompt'

// import { ReactQueryDevtools } from 'react-query/devtools'

import { AdminContext, globalStyle, FullPageLoader, ThemeProvider } from 'components'
import { config } from 'configs'
import {
    Confirm,
    NotificationProvider,
    UserObserver,
    MobxSyncFlags,
    ErrorHandling,
    authStore,
    BlockersProvider,
} from 'core'
import { Routes } from 'pages'
import GlobalProviders from 'pages/layouts/GlobalProvider'
import { registerResources } from 'resources/utils'

registerResources()
LicenseInfo.setLicenseKey(config.MUI_X_KEY)

const App = () => {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobxProvider auth={authStore}>
                <AdminContext>
                    <ThemeProvider>
                        <NotificationProvider>
                            {/* <ReactQueryDevtools initialIsOpen={false} /> */}
                            <GlobalStyles styles={globalStyle} />
                            <BlockersProvider>
                                <ErrorHandling>
                                    <GlobalProviders>
                                        <Routes />
                                    </GlobalProviders>
                                </ErrorHandling>
                                <Confirm />
                                <UserObserver />
                                <MobxSyncFlags />
                                <FullPageLoader />
                            </BlockersProvider>
                        </NotificationProvider>
                    </ThemeProvider>
                </AdminContext>
                <PWAPrompt
                    copyBody={
                        'For a better experience you can add Fleetpal ' +
                        'to your home screen and use it in fullscreen mode.'
                    }
                    copyClosePrompt="Close"
                />
            </MobxProvider>
        </LocalizationProvider>
    )
}

export default App
