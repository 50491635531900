import { type ReactNode, memo } from 'react'

import { styled } from 'lib'

const AdditionalFixedParts = memo(
    styled('div')`
        display: flex;
        flex-direction: column;
    `,
    () => true,
)

const FixedTopPart = styled(
    ({ children, className }: { children: ReactNode; className?: string }) => {
        return (
            <div className={className}>
                {children}

                <AdditionalFixedParts id="additional-fixed-parts" />
            </div>
        )
    },
)`
    margin-left: auto;
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    ${({ theme }) => theme.breakpoints.up(theme.props.mobileViewBreakpoint)} {
        width: calc(100% - ${({ theme }) => theme.props.drawerWidth}px);
    }
`

export default FixedTopPart
