import { type AuthStore } from 'core/auth'
import { costCenterFilter } from 'resources/costCenters'
import { divisionFilter } from 'resources/divisions'
import { type UnitModel } from 'resources/units'
import { capitalize } from 'utils'

import { type CostPerMeterModel } from '../CostPerMeter/CostPerMeter'

export const getUnitPrefix = (source: string): any => `unit${capitalize(source)}`

export const newSources: { [K in keyof UnitModel]?: keyof CostPerMeterModel } = {
    total: 'costPerMeterTotal',
}

export const reportCostCenterFilter = (auth: AuthStore) =>
    auth.companySettings.hasCostCenters
        ? costCenterFilter({
              id: 'totalsCostCenter',
              label: 'Cost Center',
          })
        : null

export const reportDivisionFilter = (auth: AuthStore) =>
    auth.companySettings.hasCostCenters
        ? divisionFilter({
              id: 'totalsDivision',
              label: 'Division',
          })
        : null
