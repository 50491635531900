import { useRecordContext } from 'react-admin'

import { SliderView } from 'components'
import { useFlags } from 'lib'
import { isUnitArchived, type UnitModel } from 'resources/units'
import { PageContent } from 'ui'

import UnitMap from '../UnitMap'
import {
    UnitCostPerDistanceWidget,
    UnitCostWidget,
    SinceLastServiceTrackWidget,
    UnitPMWidget,
    UnitDowntimeWidget,
    UnitDVIRWidget,
    UnitIssuesWidget,
    UnitExpirationsWidget,
} from '../Widgets'

const TopSection = () => {
    const record = useRecordContext<UnitModel>()
    const isArchived = isUnitArchived(record)
    const { flag4775UnitExpirations } = useFlags()

    const showUnitMap =
        record?.location && !isArchived && record?.telematicsData?.telematicsStatus === 'CONNECTED'

    const showDVIRwidget =
        record?.telematicsData?.telematicsStatus === 'CONNECTED' &&
        record?.telematicsData?.status === 'ACTIVE'
    const content = (
        <SliderView
            items={[
                showDVIRwidget && <UnitDVIRWidget />,
                record && !isArchived && <UnitPMWidget />,
                <UnitIssuesWidget />,
                flag4775UnitExpirations && !record?.archived && <UnitExpirationsWidget />,
                <UnitCostPerDistanceWidget />,
                <UnitCostWidget />,
                <UnitDowntimeWidget />,
                record?.lastCompletedWorkOrder && <SinceLastServiceTrackWidget />,
            ].filter(Boolean)}
        />
    )

    if (showUnitMap) {
        return (
            <>
                <UnitMap />
                <PageContent
                    py="0 !important"
                    mt="-72px"
                    // TODO: required to remove flex grow if multiple page contents in same page. It takes so much space on large screens
                    flexGrow="initial !important"
                >
                    {content}
                </PageContent>
            </>
        )
    }

    return (
        <PageContent
            bgcolor={(theme) => theme.palette.darkGray}
            flexGrow="initial !important"
        >
            {content}
        </PageContent>
    )
}

export default TopSection
