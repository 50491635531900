import { type FC } from 'react'

import { globalClassNames } from 'lib'
import { type PmModel } from 'resources/pm'
import { ck33Fields } from 'resources/vmrs'
import { Typography } from 'ui'

const PMRepositionLabel: FC<{ record: PmModel }> = ({ record }) => {
    return (
        <>
            <Typography
                variant="subtitle2"
                color="primary.main"
                lineHeight="14px"
                mb="2px"
            >
                {record.name}
            </Typography>
            <Typography
                className={globalClassNames.ellipsis}
                color="text.primary"
                variant="chartsBody"
                display="block"
            >
                {ck33Fields.self.value(record.componentData)}
            </Typography>
        </>
    )
}

export default PMRepositionLabel
