import { type FC } from 'react'

import { type SvgIconElement } from 'appTypes'
import { MoneyField } from 'components'
import { globalClassNames } from 'lib'
import { Box, Stack, Typography } from 'ui'

import ItemLabel from './ItemLabel'

const ItemRow: FC<{ name: string; price: number; onClick: () => void; Icon: SvgIconElement }> = ({
    price,
    name,
    onClick,
    Icon,
}) => {
    return (
        <Typography
            variant="body2"
            color={(theme) => theme.palette.text.primary}
            direction="row"
            component={Stack}
            justifyContent="space-between"
            className={globalClassNames.ellipsis}
        >
            <ItemLabel
                Icon={Icon}
                onClick={onClick}
            >
                {name}
            </ItemLabel>

            <Box ml="22px">
                <MoneyField value={price} />
            </Box>
        </Typography>
    )
}

export default ItemRow
