import { type ReactElement, type PropsWithChildren } from 'react'

import Icons from 'assets/icons'
import { resolveIntegerSpacedMask } from 'components'
import { ActionsMenu } from 'core'
import { alpha, styled, formatDate } from 'lib'
import { getLeft, getLeftUnitType } from 'pages/Units/PM/UnitPMCard/BottomSection'
import { getColor, getImage } from 'pages/Units/PM/UnitPMCard/UnitPMCard'
import { type PmModel, type PMIntervalStatusKeys } from 'resources/pm'
import { getMeterTypeAdornment, meterTypesConfig } from 'resources/units'
import { SvgIcon, Stack, BoxContainer, Typography } from 'ui'
import { capitalizeWithLowerCase } from 'utils'

interface OverlaySectionProps {
    title: string | ReactElement
}
interface StatusSectionProps {
    status: PMIntervalStatusKeys
    gap?: string
    colorful?: boolean
}
export const StatusSection = ({ status, gap = '18px', colorful = false }: StatusSectionProps) => {
    const formattedStatus = capitalizeWithLowerCase(status)
    return (
        <BoxContainer
            component="span"
            gap={gap}
        >
            <SvgIcon
                component={getImage(status)}
                sx={{
                    color: getColor(status),
                    width: '16px',
                    height: '16px',
                }}
            />
            <Typography
                variant="menuItem"
                color={colorful ? getColor(status) : 'text.primary'}
            >
                {formattedStatus === 'Due' ? formattedStatus + ' Soon' : formattedStatus}
            </Typography>
        </BoxContainer>
    )
}
const OverlaySection = ({ title, children }: OverlaySectionProps & PropsWithChildren) => {
    return (
        <Typography
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            color="text.disabled"
            variant="menuItem"
            display="flex"
        >
            {title}&nbsp;
            <Typography
                overflow="hidden"
                color="text.primary"
                display="flex"
            >
                {children}
            </Typography>
        </Typography>
    )
}
const BadgeButtonContainer = styled(BoxContainer)`
    color: ${({ theme }) => theme.palette.primary.main};
    background-color: ${({ theme }) => alpha(theme.palette.primary.main, 0.12)};
    height: 18px;
    width: 20px;
    cursor: pointer;
    justify-content: center;
    border-radius: 2px;
`
const IntervalsShowMoreButton = ({ record }: { record: PmModel }) => {
    const interval = record.intervals[1]

    if (!interval) {
        return null
    }

    const intervalMesure = getLeftUnitType(interval.type, true)

    return (
        <ActionsMenu
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            renderToggler={(open) => (
                <BadgeButtonContainer
                    component="span"
                    onClick={open}
                >
                    <SvgIcon
                        component={Icons.PlusOneOutlined}
                        sx={{ width: '16px', height: '16px' }}
                    />
                </BadgeButtonContainer>
            )}
            actions={() => [
                <Stack
                    key="content"
                    maxWidth="300px"
                    p="4px 12px"
                    spacing="12px"
                >
                    <Typography
                        color="text.primary"
                        variant="menuItem"
                        textTransform="capitalize"
                    >
                        {meterTypesConfig[interval.type]?.name} Meter
                    </Typography>
                    <StatusSection status={interval.status} />
                    <OverlaySection title="Last Service">
                        {interval.type === 'TIME' ? (
                            formatDate(record.lastDone, (dateFormats) => dateFormats.shortenedDate)
                        ) : (
                            <>
                                {resolveIntegerSpacedMask(interval.lastDoneMeterValue)}{' '}
                                {getMeterTypeAdornment(interval.type)}
                            </>
                        )}
                    </OverlaySection>
                    <OverlaySection title="Next Service">
                        {interval.type === 'TIME' ? (
                            formatDate(interval.overdue, (dateFormats) => dateFormats.shortenedDate)
                        ) : (
                            <>
                                {resolveIntegerSpacedMask(interval.overdue)}{' '}
                                {getMeterTypeAdornment(interval.type)}
                            </>
                        )}
                    </OverlaySection>
                    <OverlaySection title={<>Left ({intervalMesure})</>}>
                        <Typography
                            component="span"
                            textOverflow="ellipsis"
                            overflow="hidden"
                            whiteSpace="nowrap"
                        >
                            {getLeft(interval)} {getMeterTypeAdornment(interval.type)}
                        </Typography>
                        {getLeft(interval).charAt(0) !== '0' && interval.status === 'OVERDUE' && (
                            <Typography component="span">&nbsp;overdue</Typography>
                        )}
                    </OverlaySection>
                    <OverlaySection
                        title={
                            <>
                                Interval (
                                {interval.type === 'TIME'
                                    ? capitalizeWithLowerCase(interval.valueType)
                                    : intervalMesure}
                                )
                            </>
                        }
                    >
                        {resolveIntegerSpacedMask(interval.value)}{' '}
                        {interval.type === 'TIME'
                            ? interval.valueType.toLowerCase() + 's'
                            : getMeterTypeAdornment(interval.type)}
                    </OverlaySection>
                </Stack>,
            ]}
        />
    )
}
export default IntervalsShowMoreButton
