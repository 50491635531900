import { type FC, type ReactElement } from 'react'

import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import { DistanceLabel } from 'components'
import { createResource } from 'core'

import unitResource from '../unitResource'

import { type MeterModel } from './types'

export const getMetersResource = (unitId: Identifier) => {
    return createResource({
        resource: `${unitResource.resource}/${unitId}/meters`,
        name: 'unit-meters',
    })
}

interface MeterConfig {
    Icon: FC
    name: string
    id: MeterModel['type'] | 'TIME'
    adornment: string | ReactElement
}

export const meterTypesConfig: { [key in MeterConfig['id']]: MeterConfig } = {
    ENGINE_HOURS: {
        Icon: Icons.AvTimerOutlined,
        name: 'Engine Hours',
        id: 'ENGINE_HOURS',
        adornment: 'h',
    },
    HUBOMETER: {
        Icon: Icons.Hubometer,
        name: 'Hubometer',
        id: 'HUBOMETER',
        adornment: <DistanceLabel variant="abbr" />,
    },
    APU_HOURS: {
        Icon: Icons.ApuHours,
        name: 'APU Hours',
        id: 'APU_HOURS',
        adornment: 'h',
    },
    ODOMETER: {
        Icon: Icons.Odometer,
        name: 'Odometer',
        id: 'ODOMETER',
        adornment: <DistanceLabel variant="abbr" />,
    },
    TIME: { Icon: Icons.CalendarTodayOutlined, name: 'Time', id: 'TIME', adornment: '' },
}

export const getMeterTypeAdornment = (type: MeterConfig['id']) => {
    return meterTypesConfig[type]?.adornment
}

export const meterTypeChoices: MeterConfig[] = [
    meterTypesConfig.ODOMETER,
    meterTypesConfig.ENGINE_HOURS,
    meterTypesConfig.HUBOMETER,
    meterTypesConfig.APU_HOURS,
]

export const getMeterIcon = (type: MeterConfig['id']) => {
    const Comp = meterTypesConfig[type]
    if (!Comp) {
        return null
    }
    return <Comp.Icon />
}
