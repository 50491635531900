import { useEffect } from 'react'

import { useRecordContext } from 'react-admin'
import { useFormContext } from 'react-hook-form'

import {
    useUtilityDrawerContext,
    TextInput,
    TextareaInput,
    costMaskParams,
    inputQtyPositiveMask,
    TotalFormField,
} from 'components'
import { requiredValidation, validateMaxLength } from 'core'
import { type PartWithInventoryModel } from 'resources/inventory'
import { PartInput } from 'resources/parts'
import { poResource } from 'resources/purchaseOrders'
import { CK34Input } from 'resources/vmrs'
import { Typography, BoxContainer } from 'ui'

import { type LineItemsDrawerTogglerExtra } from './LineItemsDrawerToggler'

const maxCharactersValidate = validateMaxLength(30)
const qtyAndCostValidator = [validateMaxLength(10, 'Invalid value'), requiredValidation]

const LineItemsForm = () => {
    const { setValue, getValues } = useFormContext()
    const { extra }: { extra: LineItemsDrawerTogglerExtra } = useUtilityDrawerContext()
    const poRecord = extra.poRecord

    return (
        <>
            <TypeInput />
            <PartInput<PartWithInventoryModel>
                contextType={poResource.name}
                contextId={poRecord.id}
                inputTitle="Part"
                type="inventory"
                onSelectedChange={({ selected }) => {
                    setValue('partData.description', selected?.description || '')
                    setValue('partData.universalProductCode', selected?.universalProductCode || '')
                    setValue(
                        'partData.manufacturerPartNumber',
                        selected?.manufacturerPartNumber || '',
                    )
                    setValue('partData.manufacturer', selected?.manufacturer || '')
                    setValue('partData.unitOfMeasure', selected?.unitOfMeasure || '')
                    setValue('price', selected?.inventoryAcquisitionCost || 0)
                }}
                disabled={poRecord.status === 'CLOSED' || Boolean(getValues('id'))}
            />

            <TextareaInput
                source="partData.description"
                label="Description"
                disabled
            />
            <TextInput
                source="partData.universalProductCode"
                label="UPC"
                disabled
            />

            <TextInput
                source="partData.manufacturerPartNumber"
                label="Manufacturer Part Number"
                disabled
                validate={maxCharactersValidate}
            />

            <CK34Input
                label="Manufacturer"
                disabled
                source="partData.manufacturer"
            />

            <TextInput
                source="partData.unitOfMeasure"
                label="UOM"
                disabled
            />

            <TextInput
                source="quantity"
                label="Quantity"
                {...inputQtyPositiveMask(
                    poRecord.type === 'CREDIT' ? { InputProps: { startAdornment: '-' } } : {},
                )}
                validate={qtyAndCostValidator}
                disabled={poRecord.status === 'CLOSED'}
            />

            <TextInput
                source="price"
                label="Part Cost"
                {...costMaskParams}
                validate={qtyAndCostValidator}
                disabled={poRecord.status === 'CLOSED'}
            />

            <BoxContainer justifyContent="space-between">
                <Typography color="text.disabled">Total</Typography>
                <Typography
                    color="text.primary"
                    variant="subtitle2"
                >
                    <TotalFormField
                        inputOne="quantity"
                        inputTwo="price"
                    />
                </Typography>
            </BoxContainer>
        </>
    )
}

export default LineItemsForm

// data needed for the serializer
const TypeInput = () => {
    const { register } = useFormContext()
    const { extra }: { extra: LineItemsDrawerTogglerExtra } = useUtilityDrawerContext()
    const poRecord = extra.poRecord
    const record = useRecordContext()

    useEffect(() => {
        // can't set value if not a Promise, it gets cleared
        Promise.resolve().then(() => {
            register('poType', {
                value: poRecord.type,
            })
        })
    }, [record])

    return null
}
