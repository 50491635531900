import Icons from 'assets/icons'
import { urls } from 'configs'
import { inventoryFields } from 'resources/inventory'
import { issueFields } from 'resources/issues'
import { partFields } from 'resources/parts'
import { poFields } from 'resources/purchaseOrders'
import { reportsUrls } from 'resources/reports'
import { IntegrationsStatus } from 'resources/telematics'
import { unitFields } from 'resources/units'
import { vendorFields } from 'resources/vendors'
import { woFields } from 'resources/workOrders'

import { type NavItemConfig } from '../Sidebar'
import { createMenu } from '../utils'

const menu: NavItemConfig[] = [
    {
        text: 'Dashboard',
        path: '/',
        Icon: Icons.Speed,
    },
    {
        text: 'Issues',
        path: urls.issues,
        Icon: issueFields.avatar.Icon,
        ActiveIcon: issueFields.avatar.ActiveIcon,
    },
    {
        text: 'Work Orders',
        path: urls.workOrders,
        Icon: woFields.avatar.Icon,
        ActiveIcon: woFields.avatar.ActiveIcon,
    },
    {
        text: 'Sales',
        Icon: Icons.CreditCardOutlined,
        items: [
            {
                text: 'Invoices',
                path: urls.invoices,
                Icon: Icons.ReceiptOutlined,
                ActiveIcon: Icons.Receipt,
                show: ({ settings }) => settings.hasInvoicing,
            },
            {
                text: 'Customers',
                path: urls.customers,
                Icon: Icons.Customers,
                show: ({ settings }) => settings.hasCustomers,
            },
        ],
    },
    {
        text: 'Purchasing',
        Icon: Icons.ShoppingCartOutlined,
        ActiveIcon: Icons.ShoppingCart,
        items: [
            {
                text: 'Purchase Orders',
                path: urls.purchaseOrders,
                Icon: poFields.avatar.Icon,
                ActiveIcon: poFields.avatar.ActiveIcon,
            },
            {
                text: 'Vendors',
                path: urls.vendors,
                Icon: vendorFields.avatar.Icon,
            },
        ],
    },
    {
        text: 'Reports',
        Icon: Icons.BarChartOutlined,
        items: [
            {
                text: 'Financial Reports',
                Icon: Icons.PaidOutlined,
                ActiveIcon: Icons.Paid,
                items: [
                    {
                        text: 'Maintenance Cost',
                        path: reportsUrls.maintenanceCost,
                    },
                    {
                        text: 'Cost per Meter',
                        path: reportsUrls.costPerMeter,
                    },
                    {
                        text: 'Downtime by Repair Class',
                        path: reportsUrls.downtime,
                    },
                    {
                        text: 'Cost per Category',
                        path: reportsUrls.costPerCategory,
                    },
                    {
                        text: 'Cost per VMRS Group',
                        path: reportsUrls.costPerVMRSGroup,
                        show: ({ settings }) =>
                            settings.ck33GroupsEnabled && settings.ck33GroupsSelectable,
                    },
                    {
                        text: 'Total Vendor Expenses',
                        path: reportsUrls.vendorExpenses,
                    },
                    {
                        text: 'Cost per Reason for Repair',
                        path: reportsUrls.costPerReasonForRepair,
                    },
                    {
                        text: 'Unscheduled Roadside Maintenance Event',
                        path: reportsUrls.urme,
                        show: ({ flags }) => flags.flag4598MtbfUrme,
                    },
                    {
                        text: 'Mean Time Between Failures',
                        path: reportsUrls.mtbf,
                        show: ({ flags }) => flags.flag4598MtbfUrme,
                    },
                    {
                        text: 'Cost per Equipment Category',
                        path: reportsUrls.costPerEquipmentCategory,
                    },
                ],
            },
            {
                text: 'Operational Reports',
                Icon: Icons.TaskAlt,
                items: [
                    {
                        text: 'PM Intervals',
                        path: reportsUrls.intervals,
                    },
                    {
                        text: 'Unresolved Defects',
                        path: reportsUrls.defects,
                    },
                    {
                        text: 'Unit Expirations',
                        path: reportsUrls.unitExpirations,
                        show: ({ flags }) => flags.flag4775UnitExpirations,
                    },
                    {
                        text: 'Assignments',
                        path: reportsUrls.assignments,
                        show: ({ settings }) => settings.hasAssignments,
                    },
                ],
            },
        ],
    },
    {
        text: 'Units',
        path: urls.units,
        Icon: unitFields.avatar.Icon,
        ActiveIcon: unitFields.avatar.ActiveIcon,
    },
    {
        text: 'Parts',
        Icon: partFields.avatar.Icon,
        ActiveIcon: partFields.avatar.ActiveIcon,
        items: [
            {
                text: 'Parts Catalog',
                path: urls.parts,
                Icon: partFields.avatar.Icon,
                ActiveIcon: partFields.avatar.ActiveIcon,
            },
            {
                text: 'Inventory',
                path: urls.inventory,
                Icon: inventoryFields.avatar.Icon,
                show: ({ settings }) => settings.hasInventory,
            },
            {
                text: 'Vendor Parts',
                path: urls.vendorParts,
                Icon: Icons.VendorParts,
            },
        ],
    },
    {
        text: 'Users',
        path: urls.members,
        Icon: Icons.PeopleOutlined,
        ActiveIcon: Icons.People,
    },
    {
        text: 'Integrations',
        path: urls.integrations,
        Icon: Icons.WorkspacesOutlined,
        ActiveIcon: Icons.Workspaces,
        status: (render) => <IntegrationsStatus render={render} />,
    },
]

export default createMenu(menu)
