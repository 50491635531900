import { useState, type FC } from 'react'

import { type Identifier } from 'appTypes'
import { costMaskParams, DateInput, TextInput } from 'components'
import { useFormInfo } from 'context'
import { authStore, requiredValidation, type ServerInfo } from 'core'
import { PaymentTermInput } from 'resources/paymentTerms'
import { getProperty, setProperty } from 'utils'

import Attachments from './Attachments'

export interface PurchaseInvoiceFormProps {
    getSource?: (source: string) => string
    disabledInputs?: boolean
    defaultValues?: {
        term?: Identifier
    }
}

export const PurchaseInvoiceForm: FC<PurchaseInvoiceFormProps> = ({
    getSource = getSourceDefault,
    disabledInputs: disabled,
    defaultValues,
}) => {
    return (
        <>
            <InvoiceNumberInput
                source={getSource('number')}
                disabled={disabled}
            />

            <TextInput
                disabled={disabled}
                source={getSource('amount')}
                label="Amount"
                validate={requiredValidation}
                {...costMaskParams}
            />

            <DateInput
                disabled={disabled}
                source={getSource('date')}
                label="Date"
                defaultValue={new Date()}
                validate={requiredValidation}
            />

            <PaymentTermInput
                source={getSource('paymentTerm')}
                label="Payment Term"
                disabled={disabled}
                defaultValue={defaultValues?.term}
            />
            <Attachments
                disabled={disabled}
                getSource={getSource}
            />
        </>
    )
}

const getSourceDefault = (source: string) => source

interface InvoiceNumberInputProps {
    source: string
    disabled?: boolean
}

const InvoiceNumberInput: FC<InvoiceNumberInputProps> = ({ source, disabled }) => {
    const [message, setMessage] = useState('')
    const { submitValidate } = useFormInfo()

    return (
        <TextInput
            source={source}
            disabled={disabled}
            label="Invoice Number"
            validate={requiredValidation}
            helperText={message}
            onChange={() => setMessage('')}
            onBlur={async (e) => {
                if (!authStore.companyPreferences.allowDuplicatePoInvoiceNumbers) {
                    return
                }
                const value = e.target.value
                if (!value) {
                    return
                }
                const data = {}
                setProperty(data, source, value)

                const [, body] = await submitValidate(data)
                if (body) {
                    const responseInfo = getProperty(body, source) as ServerInfo
                    setMessage(responseInfo?.message || '')
                }
            }}
        />
    )
}
