import { useEffect, type FC } from 'react'

import { useFormContext } from 'react-hook-form'

import { CheckboxInput, inputPriceMaskParams, TextInput } from 'components'
import { requiredValidation } from 'core'
import { isInputDisabledDefault } from 'utils'

interface Props {
    isInputDisabled?: (source: string) => boolean
}

const ManageTaxForm: FC<Props> = ({ isInputDisabled = isInputDisabledDefault }) => {
    return (
        <>
            <TaxExemptContent />

            <TextInput
                source="pricingName"
                label="Tax Name"
                disabled
            />

            <TextInput
                source="price"
                label="Tax Amount"
                validate={requiredValidation}
                disabled={isInputDisabled('price')}
                {...inputPriceMaskParams}
            />
        </>
    )
}

export default ManageTaxForm

const TaxExemptContent = () => {
    const { getValues, watch, setValue } = useFormContext()

    const isTaxExempt = getValues('pricingIsTaxExempt') || watch('pricingIsTaxExempt')

    useEffect(() => {
        if (isTaxExempt) {
            setValue('pricingName', null)
        }
    }, [isTaxExempt])

    if (!isTaxExempt) {
        return null
    }

    return (
        <>
            <CheckboxInput
                source="pricingIsTaxExempt"
                checked
                label="Tax Exempt"
                disabled
            />

            <TextInput
                source="pricingTaxExemptNumber"
                label="Tax Exempt Number"
                disabled
            />
        </>
    )
}
