import { formatPercent, formatMoney } from 'components'
import { useCreateResourcePath } from 'core'
import { WidgetListContainer } from 'pages/Dashboard/components/WidgetBase/styled'
import LineItemsTooltip from 'pages/WorkOrders/Jobs/WorkOrderJobCard/components/LineItemsTooltip'
import { vendorsResource } from 'resources/vendors'
import { Typography } from 'ui'

import { WidgetListDescription, WidgetListRowBase } from '../../components'
import { type VendorExpensesListType } from '../types'

interface TopSpendersProps {
    data: VendorExpensesListType
    description: string
    validData: boolean
}
const VendorExpensesList = ({ data = [], description, validData }: TopSpendersProps) => {
    const createPath = useCreateResourcePath()

    const list = data.slice(0, 3).flatMap(({ name, total, totalPercent, id, ...ratioData }) => {
        if (!total && total !== 0) {
            return []
        }

        const vendorUrl = createPath({
            resource: vendorsResource.resource,
            id,
            type: 'edit',
        })

        return (
            <WidgetListRowBase
                key={id}
                link={vendorUrl}
                label={name}
                hideClarification={!validData || total <= 0}
                clarification={
                    <>
                        {formatPercent(totalPercent)}{' '}
                        <Typography
                            color="text.disabled"
                            variant="chartListItem"
                        >
                            of total vendor expenses
                        </Typography>
                    </>
                }
                total={<LineItemsTooltip data={ratioData}>{formatMoney(total)}</LineItemsTooltip>}
            />
        )
    })

    if (!list.length) {
        return <WidgetListDescription>{description}</WidgetListDescription>
    }

    return <WidgetListContainer>{list}</WidgetListContainer>
}
export default VendorExpensesList
