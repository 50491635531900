import { useEffect } from 'react'

import { useFormContext } from 'react-hook-form'

import { useUtilityDrawerContext } from 'components'
import { WorkOrderForm } from 'pages/WorkOrders/components'
import { isVendorWo, JobForm } from 'resources/workOrders'

import UseWOStepper from './UseWOStepper'
import { useStepsContext } from './UseWOStepsContext'
import { type UseWOExtraState } from './useAttachWO'

const NameInputDefaultValueHandler = () => {
    const { setValue, getValues } = useFormContext()
    useEffect(() => {
        const name = getValues('name')
        if (name) {
            setValue('name', name, {
                shouldDirty: true,
                shouldTouch: true,
                shouldValidate: true,
            })
        }
    }, [])

    return null
}
const UseWOFormContent = () => {
    const { step } = useStepsContext()
    const { extra } = useUtilityDrawerContext()
    const extraState: UseWOExtraState = extra
    const { options, workOrder, unit } = extraState
    const isFromDvir = extraState?.parentRecord?.dvirs

    return (
        <>
            <UseWOStepper />
            {step === 1 ? (
                <WorkOrderForm
                    disabledFields={options?.woDisabledFields}
                    hiddenFields={options?.woHiddenFields}
                />
            ) : (
                <>
                    <JobForm
                        isInputDisabled={(source) => options?.jobDisabledFields?.[source]}
                        reasonForRepairDefaultValueByCode={isFromDvir ? '04' : undefined}
                        isHidden={(source) => {
                            if (
                                source === 'billable' &&
                                (!unit.customer || isVendorWo(workOrder))
                            ) {
                                return true
                            }
                        }}
                    />
                    <NameInputDefaultValueHandler />
                </>
            )}
        </>
    )
}

export default UseWOFormContent
