import { type ReactElement, type FC } from 'react'

import { inject, observer } from 'mobx-react'

import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import { urls } from 'configs'
import { type AuthStore } from 'core'
import {
    globalClassNames,
    Link,
    matchPath,
    styled,
    useNavigate,
    useQueryClient,
    useResetQueries,
    withColor,
} from 'lib'
import { addressFields } from 'resources/address'
import { shopFields, shopUrls } from 'resources/shops'
import { type ShopModel } from 'resourcesBase'
import {
    Box,
    BoxContainer,
    Button,
    DataAvatar,
    Divider,
    List,
    MenuItem,
    Stack,
    Typography,
} from 'ui'

import { useSidebarFunctions } from '../SidebarContext'

import AccountDetails from './AccountDetails'

export const SidebarShopContent: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        const shop = auth.shop
        const shopId = auth.shopId
        const shops = auth.user.membership.shops

        return (
            <Box pt="12px">
                <AccountDetails short />
                <Divider />
                <Typography
                    variant="subtitle2"
                    color={withColor('text.primary')}
                    my="14px"
                >
                    Selected Repair Shop
                </Typography>

                <Box p="12px 16px">
                    {shop ? (
                        <ShopDetails
                            record={shop}
                            bottomContent={
                                <div>
                                    <Button
                                        component={Link}
                                        to={shopUrls.getOne(shopId)}
                                        variant="text"
                                        size="small"
                                        endIcon={<Icons.ArrowForward />}
                                    >
                                        Go To Repair Shop
                                    </Button>
                                </div>
                            }
                        />
                    ) : (
                        <StopTitle title={shopFields.self.allSelectedText} />
                    )}
                </Box>

                {shops.length > 1 ? (
                    <SelectShop
                        shops={shops.filter((shop) => shop.id !== shopId)}
                        setShop={auth.setShop}
                        shopId={shopId}
                    />
                ) : null}
            </Box>
        )
    }),
)

interface SelectShopProps {
    shops: ShopModel[]
    setShop: (shop: Identifier | null) => void
    shopId: Identifier | null
}

const SelectShop: FC<SelectShopProps> = ({ shops, setShop: setShopProp, shopId }) => {
    const { close } = useSidebarFunctions()
    const reset = useResetQueries()
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    if (!shops.length) {
        return null
    }

    const setShop = (shopId: Identifier | null) => {
        setShopProp(shopId)
        if (matchPath(urls.root, window.location.pathname)) {
            reset()
        } else {
            navigate(urls.root)
            queryClient.clear()
        }
        close()
    }

    return (
        <>
            <Divider />
            <Typography
                variant="subtitle2"
                my="17px"
            >
                Switch Repair Shop
            </Typography>

            <List sx={{ p: 0 }}>
                {shopId ? (
                    <ShopItem
                        role="button"
                        onClick={() => setShop(null)}
                    >
                        <StopTitle title="View All" />
                    </ShopItem>
                ) : null}
                {shops.map((shop) => (
                    <ShopItem
                        role="button"
                        key={shop.id}
                        onClick={() => setShop(shop.id)}
                    >
                        <ShopDetails record={shop} />
                    </ShopItem>
                ))}
            </List>
        </>
    )
}

const ShopItem = styled(MenuItem)`
    border-radius: 8px;
    white-space: normal;
    padding: 12px 16px;
`

const ShopDetails: FC<{ record: ShopModel; bottomContent?: ReactElement }> = ({
    record,
    bottomContent,
}) => {
    const address = addressFields.self.row1(record)
    const details = addressFields.self.row2(record)

    return (
        <Stack
            gap="4px"
            width="100%"
        >
            <StopTitle
                record={record}
                title={record.name}
            />
            {address ? (
                <Typography
                    variant="helperText"
                    color={withColor('text.primary')}
                    className={globalClassNames.ellipsis}
                >
                    {address}
                </Typography>
            ) : null}
            {details ? (
                <Typography
                    variant="helperText"
                    color={withColor('text.primary')}
                    className={globalClassNames.ellipsis}
                >
                    {details}
                </Typography>
            ) : null}
            {bottomContent}
        </Stack>
    )
}

const StopTitle: FC<{
    record?: Pick<ShopModel, 'logo' | 'avatarColor' | 'name'>
    title: string
}> = ({ record, title }) => {
    return (
        <BoxContainer
            width="100%"
            gap="12px"
        >
            <Typography
                variant="body2"
                color={withColor('text.primary')}
                flexGrow={1}
                className={globalClassNames.ellipsis}
            >
                {title}
            </Typography>
            <DataAvatar
                {...shopFields.avatar.props(record, {
                    initials: { fontSize: '12px' },
                    avatar: { size: '18px' },
                })}
                sx={{ width: '24px', height: '24px' }}
            />
        </BoxContainer>
    )
}
