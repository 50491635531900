import { type FC, type ReactElement } from 'react'

import { type Identifier } from 'appTypes'
import { useOpenUtilityDrawer, UtilityDrawerEditor } from 'components'
import { type Serializer } from 'core'
import {
    deleteInvoiceItemActionParams,
    getInvoiceItemResource,
    type InvoiceModel,
    type InvoiceService,
    InvoiceStatusKeys,
    InvoiceTypeKeys,
} from 'resources/invoices'

import ServiceForm, { type ServiceFormProps } from './ServiceForm'

interface InvoiceServiceDrawerProps extends Pick<ServiceFormProps, 'isDisabled'> {
    children: (open: () => void) => ReactElement
    invoice: InvoiceModel
    id?: Identifier
    type?: InvoiceTypeKeys
}

const InvoiceServiceDrawer = ({
    id,
    children,
    type = InvoiceTypeKeys.STANDARD,
    isDisabled,
    invoice,
}: InvoiceServiceDrawerProps) => {
    const open = useOpenUtilityDrawer()
    const resource = getInvoiceItemResource(invoice.id, id ? undefined : 'add_service')
    const readOnly = invoice.status !== InvoiceStatusKeys.OPEN

    return children(() => {
        open({
            extraArgs: {
                resource,
                id,
            },
            drawerArgs: {
                title: id ? <EditTitle readOnly={readOnly} /> : 'Add Service',
                renderWrapper: (props) => (
                    <UtilityDrawerEditor
                        serializer={serializer}
                        {...props}
                    />
                ),
                renderContent: () => (
                    <FormContent
                        isDisabled={isDisabled}
                        id={id}
                        type={type}
                        record={invoice}
                    />
                ),
                renderTopRight:
                    type === InvoiceTypeKeys.WORK_ORDER || readOnly
                        ? null
                        : (render) => render(deleteInvoiceItemActionParams),
                renderBottomRight: readOnly
                    ? null
                    : (render) =>
                          render({
                              label: id ? undefined : 'Add',
                          }),
                renderBottomLeft: readOnly ? (render) => render({ children: 'Close' }) : undefined,
            },
        })
    })
}

export default InvoiceServiceDrawer

const EditTitle: FC<{ readOnly: boolean }> = ({ readOnly }) => {
    if (readOnly) {
        return <>View Service</>
    }

    return <>Edit Service</>
}

interface FormContentProps extends Pick<ServiceFormProps, 'isDisabled'> {
    id?: Identifier
    type: InvoiceTypeKeys
    record: InvoiceModel
}

const FormContent: FC<FormContentProps> = ({ isDisabled, id, type, record }) => {
    return (
        <ServiceForm
            isHidden={(source) => {
                if (type === InvoiceTypeKeys.STANDARD) {
                    return source === 'orderPrice'
                }
            }}
            isDisabled={(source) => {
                if (!id) {
                    return isDisabled?.(source)
                }

                if (record.status !== InvoiceStatusKeys.OPEN) {
                    return true
                }

                if (type === InvoiceTypeKeys.WORK_ORDER) {
                    return source === 'orderPrice' || source === 'description'
                }
            }}
        />
    )
}

const serializer: Serializer<InvoiceService> = ['description', 'price']
