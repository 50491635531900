import { type FC } from 'react'

import { type SvgIconElement } from 'appTypes'
import { globalClassNames } from 'lib'
import { Anchor, FormHelperText, SvgIcon } from 'ui'

interface Props {
    Icon: SvgIconElement
    children: string
    onClick: () => void
}

const ItemLabel: FC<Props> = ({ Icon, children, onClick }) => {
    return (
        <Anchor
            onClick={onClick}
            display="flex"
            alignItems="center"
            component="div"
            role="button"
            overflow="hidden"
        >
            <SvgIcon
                inheritViewBox
                component={Icon}
                sx={{ width: '16px', height: '16px', mr: '8px' }}
            />
            <FormHelperText
                className={globalClassNames.ellipsis}
                sx={{ color: 'inherit' }}
            >
                {children}
            </FormHelperText>
        </Anchor>
    )
}

export default ItemLabel
