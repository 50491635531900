import Icons from 'assets/icons'
import { formatMoney } from 'components'
import { useCreateResourcePath } from 'core/resource'
import { globalClassNames } from 'lib'
import { WidgetListContainer } from 'pages/Dashboard/components/WidgetBase/styled'
import { unitResource } from 'resources/units'
import { Spacer } from 'ui'

import { type listOrderType } from '../../FleetCostPerDistanceWidget/types'
import { Arrow, WidgetListDescription, WidgetListRowBase } from '../../components'
import { type CostPerHourListType } from '../types'

interface CostPerHourListProps {
    data: CostPerHourListType
    listOrder: listOrderType
    description: string
}

const CostPerHourList = ({ data, description, listOrder }: CostPerHourListProps) => {
    const createPath = useCreateResourcePath()

    const sortedData = data.length
        ? data.sort((a, b) =>
              listOrder === 'DESC'
                  ? b.differenceWithAverage - a.differenceWithAverage
                  : a.differenceWithAverage - b.differenceWithAverage,
          )
        : data

    const list = sortedData
        .slice(0, 3)
        .flatMap(({ number, differenceWithAverage, id, costPerHour, archived }) => {
            const isBelowAvg = differenceWithAverage < 0
            const unitEditUrl = createPath({
                resource: unitResource.resource,
                id,
                type: 'edit',
            })
            return (
                <WidgetListRowBase
                    key={id}
                    link={unitEditUrl}
                    icon={(render) =>
                        archived ? render({ icon: Icons.Archived, tooltip: 'Archived' }) : undefined
                    }
                    label={number}
                    clarification={
                        <>
                            {formatMoney(Math.abs(differenceWithAverage))}
                            {isBelowAvg ? ' below ' : ' above '}
                            average
                        </>
                    }
                    total={
                        <Spacer className={globalClassNames.noWrap}>
                            <Arrow
                                direction={isBelowAvg ? 'down' : 'up'}
                                sx={(theme) => ({
                                    width: '16px',
                                    height: '16px',
                                    color: theme.palette.charts[isBelowAvg ? 'greenBody' : 'red'],
                                })}
                            />
                            {formatMoney(costPerHour)}
                        </Spacer>
                    }
                />
            )
        })

    if (!list.length) {
        return <WidgetListDescription>{description}</WidgetListDescription>
    }

    return <WidgetListContainer>{list}</WidgetListContainer>
}
export default CostPerHourList
