import { type FC } from 'react'

import { useRecordContext } from 'react-admin'

import Icons from 'assets/icons'
import {
    InfoCard,
    InfoCardHeader,
    InfoCardRowBase,
    InfoCardRows,
    InfoCardTitle,
    MoneyField,
} from 'components'
import { InvoiceStatusKeys, type InvoiceModel } from 'resources/invoices'
import { Alert, Divider, IconBox } from 'ui'

import ItemRow from './ItemRow'
import ManageTotalDrawerToggler from './ManageTotalDrawerToggler'
import { ManageFeeDrawerToggler } from './fees'
import { ManageTaxDrawerToggler } from './taxes'

const TotalCard: FC = () => {
    const {
        fees,
        taxes,
        total,
        id,
        status,
        removeFees,
        removeTaxes,
        totalOriginDirect,
        totalOriginWorkOrder,
    } = useRecordContext<InvoiceModel>()
    const readOnly = status !== InvoiceStatusKeys.OPEN

    return (
        <InfoCard>
            <InfoCardHeader
                title="Total"
                action={
                    <ManageTotalDrawerToggler
                        readOnly={readOnly}
                        id={id}
                        defaultValues={{
                            removeFees,
                            removeTaxes,
                        }}
                    >
                        {(open) => (
                            <IconBox onClick={open}>
                                <Icons.SettingsOutlined />
                            </IconBox>
                        )}
                    </ManageTotalDrawerToggler>
                }
            />
            <InfoCardRowBase label={<InfoCardTitle>Subtotal</InfoCardTitle>}>
                <MoneyField value={totalOriginDirect + totalOriginWorkOrder || 0} />
            </InfoCardRowBase>
            <InfoCardRows>
                {removeFees ? (
                    <Alert severity="info">
                        Fees are not applied to this invoice due to a setting.
                    </Alert>
                ) : (
                    fees.map((fee) => (
                        <ManageFeeDrawerToggler
                            id={fee.id}
                            key={fee.id}
                            invoiceId={id}
                            readOnly={readOnly}
                        >
                            {(open) => (
                                <ItemRow
                                    onClick={open}
                                    name={fee.pricingName}
                                    price={fee.price}
                                    Icon={Icons.Fee}
                                />
                            )}
                        </ManageFeeDrawerToggler>
                    ))
                )}
                {removeTaxes ? (
                    <Alert severity="info">
                        Taxes are not applied to this invoice due to a setting.
                    </Alert>
                ) : (
                    taxes.map((tax) => (
                        <ManageTaxDrawerToggler
                            key={tax.id}
                            id={tax.id}
                            invoiceId={id}
                            readOnly={readOnly}
                        >
                            {(open) => (
                                <ItemRow
                                    onClick={open}
                                    name={tax.pricingName}
                                    price={tax.price}
                                    Icon={Icons.Tax}
                                />
                            )}
                        </ManageTaxDrawerToggler>
                    ))
                )}
            </InfoCardRows>
            <Divider />
            <InfoCardRows offsetBottom="0">
                <InfoCardRowBase label={<InfoCardTitle>Grand Total</InfoCardTitle>}>
                    <InfoCardTitle>
                        <MoneyField value={total || 0} />
                    </InfoCardTitle>
                </InfoCardRowBase>
            </InfoCardRows>
        </InfoCard>
    )
}

export default TotalCard
