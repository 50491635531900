import { Link } from 'react-router-dom'

import images from 'assets/images'
import { urls } from 'configs'
import { Img } from 'ui'

import Item from './Item'

const Logo = () => {
    return (
        <Link to={urls.root}>
            <Item py="24px">
                <Img
                    alt="Fleetpal"
                    src={images.initialLogo}
                    sx={{
                        width: '32px',
                        height: '32px',
                        objectFit: 'cover',
                    }}
                />
            </Item>
        </Link>
    )
}

export default Logo
