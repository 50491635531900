import { type FC } from 'react'

import { useFormContext } from 'react-hook-form'

import {
    costMaskParams,
    inputQuantityMaskParams,
    MoneyFormTotal,
    TextareaInput,
    TextInput,
} from 'components'
import { maxLengthValidationText, requiredValidation } from 'core'
import { type InvoiceLabor } from 'resources/invoices'
import { CK15Input, CK18Input, CK33InputWithoutParts } from 'resources/vmrs'
import { SectionTitleSmall } from 'ui'

export interface InvoiceLaborFormProps {
    isDisabled?: (source: keyof InvoiceLabor) => boolean
    isHidden?: (source: keyof InvoiceLabor) => boolean
}

const defaultDisabled = () => false

const descriptionValidation = [maxLengthValidationText, requiredValidation]

const InvoiceLaborForm = ({ isDisabled = defaultDisabled, isHidden }: InvoiceLaborFormProps) => {
    return (
        <>
            <SectionTitleSmall>Basic Details</SectionTitleSmall>

            {isHidden?.('component') ? null : (
                <CK33InputWithoutParts
                    disabled
                    required
                />
            )}

            <TextareaInput
                source="description"
                label="Description"
                disabled={isDisabled('description')}
                validate={descriptionValidation}
            />

            {isHidden?.('cause') ? null : (
                <CK18Input
                    source="cause"
                    disabled
                    label="Cause"
                />
            )}

            {isHidden?.('correction') ? null : <CK15Input disabled />}

            <TextInput
                source="orderPrice"
                label="Hourly Labor Rate"
                {...costMaskParams}
                disabled
            />

            <SaleRateInput disabled={isDisabled('price')} />

            {isHidden?.('orderQuantity') ? null : (
                <TextInput
                    source="orderQuantity"
                    label="Work Order Qty/Hours"
                    {...inputQuantityMaskParams}
                    disabled
                />
            )}

            <QuantityInput
                disabled={isDisabled('quantity')}
                isOrderQuantity={!isHidden?.('orderQuantity')}
            />

            <MoneyFormTotal
                title="Total"
                inputOne="quantity"
                inputTwo="price"
            />
        </>
    )
}

const QuantityInput: FC<{ disabled: boolean; isOrderQuantity: boolean }> = ({
    disabled,
    isOrderQuantity,
}) => {
    const { watch, getValues } = useFormContext()

    const orderQuantity = Number(watch('orderQuantity') | getValues('orderQuantity'))
    const quantity = Number(watch('quantity') | getValues('quantity'))

    return (
        <TextInput
            source="quantity"
            label="Qty/Hours to Invoice"
            disabled={disabled}
            {...inputQuantityMaskParams}
            validate={requiredValidation}
            helperText={
                isOrderQuantity && orderQuantity > quantity
                    ? `${orderQuantity - quantity} qty/hours will not be billed`
                    : undefined
            }
        />
    )
}
export const SaleRateInput = ({ disabled }: { disabled: boolean }) => {
    const { watch, getValues } = useFormContext()

    const laborRate = watch('orderPrice') | getValues('orderPrice')
    const saleRate = watch('price') | getValues('price')

    return (
        <TextInput
            source="price"
            label="Labor Sale Rate"
            {...costMaskParams}
            disabled={disabled}
            helperText={
                saleRate && Number(saleRate) < Number(laborRate)
                    ? 'Labor sale rate below hourly labor rate'
                    : ''
            }
            validate={requiredValidation}
        />
    )
}
export default InvoiceLaborForm
