import { type NoInfer } from 'appTypes'
import { type Column, type DataListDetails } from 'components'

import { type UnitStatusModel } from '../types'

const source = 'position' as const
const label = 'Position'

const value = (record: UnitStatusModel) => {
    if (!record) {
        return null
    }
    return record.position
}

const tableColumn = <Source extends string = typeof source>() =>
    ({
        field: source as NoInfer<Source>,
        headerName: label,
        renderCell: ({ row }) => value(row),
    }) as const satisfies Column

const dataCardRow = <Source extends string = typeof source>() =>
    ({
        source: source as NoInfer<Source>,
        label,
        render: (_, data) => value(data),
    }) as const satisfies DataListDetails<any, any>

const position = {
    source,
    label,
    value,
    tableColumn,
    dataCardRow,
}

export default position
