import { type FC } from 'react'

import { inject, observer } from 'mobx-react'
import { useRecordContext } from 'react-admin'

import { InfoCard, InfoCardDetails } from 'components'
import { type AuthStore } from 'core'
import { dateReadableFormat } from 'lib'
import { meterTypesConfig } from 'resources/units'
import { isVendorWo, type WorkOrderModel } from 'resources/workOrders'

const DescriptionCreatenOnBy: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        const workOrder = useRecordContext<WorkOrderModel>()

        return (
            <InfoCard>
                <InfoCardDetails<WorkOrderModel>
                    details={[
                        auth.companySettings.hasInvoicing &&
                        workOrder.unitData.customer &&
                        !isVendorWo(workOrder)
                            ? {
                                  label: 'Customer PO Number',
                                  source: ({ salesOrderData }) => salesOrderData?.poNumber,
                              }
                            : null,
                        {
                            label: 'Meter Type',
                            source: ({ meterType }) => meterTypesConfig[meterType]?.name,
                        },
                        {
                            label: 'Meter Value',
                            source: 'meterValue',
                        },
                        {
                            label: 'Created on',
                            source: ({ created }) => dateReadableFormat(created),
                        },
                        {
                            label: 'Created By',
                            source: ({ createdBy }) => createdBy?.name || createdBy?.email,
                        },
                    ]}
                />
            </InfoCard>
        )
    }),
)

export default DescriptionCreatenOnBy
