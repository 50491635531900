import { Slider, type SliderProps } from 'components'
import { useTheme, useFlags } from 'lib'
import { useTelematicsContext } from 'resources/telematics'
import { gridItemLayoutColumns } from 'ui'

import {
    MonitorDVIR,
    MonitorExpirations,
    MonitorIntegrations,
    MonitorIssues,
    MonitorPM,
    MonitorWOPriority,
    MonitorWORepairClass,
} from './Monitor'

const DashboardSlider = () => {
    const theme = useTheme()
    const { flag4775UnitExpirations } = useFlags()
    const { hasConnection } = useTelematicsContext()
    const sliderItems: SliderProps['items'] = [
        hasConnection && <MonitorDVIR key="dvir" />,
        <MonitorPM key="pm" />,
        <MonitorIssues key="issues" />,
        <MonitorWOPriority key="wo-priority" />,
        <MonitorWORepairClass key="wo-repair-class" />,
        flag4775UnitExpirations && <MonitorExpirations />,
        <MonitorIntegrations key="integrations" />,
    ].filter(Boolean)

    return (
        <Slider
            items={sliderItems}
            slidesToShow={4}
            responsive={[
                {
                    breakpoint: theme.breakpoints.values.lg,
                    settings: {
                        slidesToShow: 12 / gridItemLayoutColumns.md,
                    },
                },
                {
                    breakpoint: theme.breakpoints.values.md,
                    settings: {
                        arrows: false,
                        slidesToShow: 1.9,
                        swipe: true,
                    },
                },
                {
                    breakpoint: theme.breakpoints.values.sm,
                    settings: {
                        arrows: false,
                        slidesToShow: 1.09,
                        swipe: true,
                    },
                },
            ]}
        />
    )
}

export default DashboardSlider
