import name from './name'
import position from './position'
import usage from './usage'

const unitStatusFields = {
    name,
    position,
    usage,
}
export default unitStatusFields
