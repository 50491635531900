import { type FC } from 'react'

import Icons from 'assets/icons'
import { BoxContainer } from 'ui'

import { type UnitStatusModel } from '../types'

import BaseStatusContent from './BaseStatusContent'
import StatusMenu from './StatusMenu'

interface StatusFieldProps {
    selector?: boolean
    record: UnitStatusModel
}

const StatusField: FC<StatusFieldProps> = ({ selector, record }) => {
    if (!record) {
        return null
    }

    if (selector) {
        return (
            <StatusMenu
                renderToggler={(open, isOpen) => (
                    <BoxContainer
                        sx={{ cursor: 'pointer' }}
                        onClick={(event) => {
                            open(event)
                        }}
                        role="button"
                        gap="4px"
                    >
                        <BaseStatusContent color={record.color}>{record.name}</BaseStatusContent>
                        {isOpen ? (
                            <Icons.KeyboardArrowUpOutlined />
                        ) : (
                            <Icons.KeyboardArrowDownOutlined />
                        )}
                    </BoxContainer>
                )}
            />
        )
    }

    return <BaseStatusContent color={record.color}>{record.name}</BaseStatusContent>
}

export default StatusField
