import { type FC } from 'react'

import { inject, observer } from 'mobx-react'

import {
    type CardListConfig,
    type DatagridColumnsProps,
    type FilterConfig,
    type ListSortContentProps,
    LinkArrowButton,
    ListFilterValueInput,
    formatMoney,
} from 'components'
import { DatagridLink } from 'components/Datagrid'
import { ResourceContextProviderWithClearEffect, excludeNulls, type SortPayload } from 'core'
import { type AuthStore } from 'core/auth'
import { getListData } from 'core/data'
import { globalClassNames } from 'lib'
import { useResources } from 'resources'
import { LineItemTypeKeys } from 'resources/lineItems'
import { tagFields } from 'resources/tags'
import {
    type UnitAggregates,
    type UnitModel,
    unitResource,
    isUnitArchived,
    unitAspects,
    ArchivedUnitBadge,
} from 'resources/units'
import unitFields from 'resources/units/fields'
import { ck2Fields, ck34Fields } from 'resources/vmrs'
import { woFields } from 'resources/workOrders'
import { PageContent, BoxContainer, StyledElement } from 'ui'

import { navigateToUnit } from '../CostPerMeter/CostPerMeter'
import { UnitCardTitleLink } from '../Downtime/components'
import { ListBaseWithPeriod, ListWithPeriodSelector, ReportsHeader } from '../components'
import { reportCostCenterFilter, reportDivisionFilter } from '../utils'

interface totalsModel {
    totalFees: number
    totalLabor: number
    totalParts: number
    totalServices: number
    totalTax: number
    totalsReasonForRepair: number
}
const defaultSort: SortPayload<UnitModel> = {
    field: 'total',
    order: 'DESC',
}

const cardsCfg: CardListConfig<UnitModel & totalsModel> = {
    titleSource: (record) => <UnitCardTitleLink record={record} />,
    disableTitleLink: true,
    defaultImage: null,
    details: [
        unitFields.name.dataCardRow({
            headerName: unitFields.name.longLabel,
            dataToValue: (record) => record.name,
        }),
        woFields.total.dataCardRow(LineItemTypeKeys.PART),
        woFields.total.dataCardRow(LineItemTypeKeys.LABOR),
        woFields.total.dataCardRow(LineItemTypeKeys.SERVICE),
        woFields.total.dataCardRow(LineItemTypeKeys.FEE),
        woFields.total.dataCardRow(LineItemTypeKeys.TAX),
        woFields.total.dataCardRow(),
    ],
    action: (record) => <LinkArrowButton path={navigateToUnit(record)} />,
}

const MaintenanceCost: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        const { unitStatus } = useResources()

        const columnsCfg: DatagridColumnsProps<UnitModel & totalsModel> = {
            resetColumns: {
                created: false,
                licensePlate: false,
                vin: false,
                vmrsEquipmentCategory: false,
                model: false,
                modelYear: false,
                color: false,
                status: false,
            },
            mainField: unitFields.number.source,
            pinnedColumns: {
                right: [woFields.total.getConfig().source],
            },
            constantColumns: {
                [woFields.total.getConfig().source]: true,
            },
            checkboxSelection: false,
            columns: [
                {
                    field: unitFields.number.source,
                    headerName: unitFields.number.label,
                    renderCell: (params) => {
                        const { value, ...restParams } = params
                        return (
                            <DatagridLink
                                {...restParams}
                                resource={unitResource.resource}
                                value={
                                    <BoxContainer>
                                        <StyledElement className={globalClassNames.ellipsis}>
                                            {value}
                                        </StyledElement>
                                        {isUnitArchived(params.row) && <ArchivedUnitBadge />}
                                    </BoxContainer>
                                }
                            />
                        )
                    },
                },
                unitFields.name.tableColumn({
                    headerName: unitFields.name.longLabel,
                    dataToValue: (record) => record.name,
                }),
                woFields.total.tableColumn(LineItemTypeKeys.PART),
                woFields.total.tableColumn(LineItemTypeKeys.LABOR),
                woFields.total.tableColumn(LineItemTypeKeys.SERVICE),
                woFields.total.tableColumn(LineItemTypeKeys.FEE),
                woFields.total.tableColumn(LineItemTypeKeys.TAX),
                woFields.total.tableColumn(),
                unitFields.created.tableColumn({
                    dataToValue: (record) => record.created,
                }),
                unitFields.licensePlate.tableColumn({
                    dataToValue: (record) => record.licensePlate,
                }),
                unitFields.vin.tableColumn({
                    dataToValue: (record) => record.vin,
                }),
                ck2Fields.self.column({
                    dataToRecord: (data) => data.vmrsEquipmentCategoryData,
                    id: 'vmrsEquipmentCategory',
                }),
                unitFields.model.tableColumn({
                    dataToValue: (record) => record.model,
                }),
                unitFields.modelYear.tableColumn({
                    dataToValue: (record) => record.modelYear,
                }),
                unitFields.color.tableColumn({
                    dataToValue: (record) => record.color,
                }),
                unitStatus.tableColumn({
                    label: unitStatus.longLabel,
                    render: unitStatus.valueByParent<UnitModel>,
                }),
            ],
            actions: null,
        }

        const sortCfg: ListSortContentProps<UnitModel> = {
            sortBy: [
                woFields.total.sort(LineItemTypeKeys.PART),
                woFields.total.sort(LineItemTypeKeys.LABOR),
                woFields.total.sort(LineItemTypeKeys.SERVICE),
                woFields.total.sort(LineItemTypeKeys.FEE),
                woFields.total.sort(LineItemTypeKeys.TAX),
                woFields.total.sort(),
                unitFields.number.sort(),
                unitFields.name.sort({ label: unitFields.name.longLabel }),
                unitFields.created.sort(),
                unitFields.licensePlate.sort(),
                unitFields.vin.sort(),
                ck2Fields.self.sort({ id: 'vmrsEquipmentCategory' }),
                unitFields.model.sort(),
                unitFields.modelYear.sort(),
                unitFields.color.sort(),
                unitStatus.sort({ label: unitStatus.longLabel }),
            ],
        }

        const filtersCfg: FilterConfig<UnitModel & totalsModel> = {
            filters: [
                woFields.total.filter(LineItemTypeKeys.PART),
                woFields.total.filter(LineItemTypeKeys.LABOR),
                woFields.total.filter(LineItemTypeKeys.SERVICE),
                woFields.total.filter(LineItemTypeKeys.FEE),
                woFields.total.filter(LineItemTypeKeys.TAX),
                woFields.total.filter(),
                {
                    id: 'totalsReasonForRepair',
                    label: 'Reason for Repair',
                    renderComponent: (props) => (
                        <ListFilterValueInput
                            {...props}
                            withOperator
                        />
                    ),
                },
                unitFields.created.filter(),
                unitFields.number.filter(),
                unitFields.name.sort({ label: unitFields.name.longLabel }),
                unitFields.vin.filter(),
                unitFields.licensePlate.filter(),
                unitStatus.filter({ label: unitStatus.longLabel }),
                ck2Fields.self.filter({ id: 'vmrsEquipmentCategory', label: 'Equipment Category' }),
                ck34Fields.self.filter({ id: 'vmrsManufacturer', label: 'Manufacturer/Make' }),
                unitFields.model.filter(),
                unitFields.modelYear.filter(),
                ck34Fields.self.filter({ id: 'engineVmrsManufacturer', label: 'Engine Make' }),
                unitFields.engineModel.filter(),
                unitFields.engineHp.filter(),
                ck34Fields.self.filter({
                    id: 'transmissionVmrsManufacturer',
                    label: 'Transmission Make',
                }),
                unitFields.transmissionModel.filter(),
                unitFields.transmissionGears.filter(),
                unitFields.color.filter(),
                unitFields.tireSize.filter(),
                tagFields.self.filter(),
                reportCostCenterFilter(auth),
                reportDivisionFilter(auth),
                { id: 'archived', label: 'Archived Unit' },
            ],
        }

        return (
            <ResourceContextProviderWithClearEffect value={unitResource}>
                <ListBaseWithPeriod
                    sort={defaultSort}
                    filter={{ withAspects: [unitAspects.totals], ...excludeNulls }}
                    preferencesName="maintenance-cost"
                >
                    <ReportsHeader
                        renderTotal={(list) => {
                            const { total: totalCount } = list
                            const listData = getListData<UnitAggregates>(list)

                            return (
                                <>
                                    {totalCount} | {formatMoney(listData.total || 0)} total
                                </>
                            )
                        }}
                    >
                        Maintenance Cost
                    </ReportsHeader>
                    <PageContent>
                        <ListWithPeriodSelector
                            exportFileName="maintenance-cost"
                            filtersCfg={filtersCfg}
                            sortCfg={sortCfg}
                            columnsCfg={columnsCfg}
                            cardsCfg={cardsCfg}
                        />
                    </PageContent>
                </ListBaseWithPeriod>
            </ResourceContextProviderWithClearEffect>
        )
    }),
)

export default MaintenanceCost
