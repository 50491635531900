import { useRef } from 'react'

import { useListContext } from 'react-admin'

const useFirstListLoad = (loading: boolean | undefined) => {
    const isLoading = useListContext().isLoading
    const finalLoading = loading || isLoading
    const firstLoad = useRef(finalLoading)

    if (!finalLoading) {
        firstLoad.current = false
    }

    return firstLoad.current
}

export default useFirstListLoad
