import { useListContext } from 'react-admin'

import Icons from 'assets/icons'
import { type TelematicsUnitModel } from 'resources/telematics'
import { IconButton, HideOnBreakpoint, Button } from 'ui'

import { useSyncedStateContext } from '../SyncedStateContext'

const SyncOffManyFromListAction = () => {
    const { selectedIds, data } = useListContext<TelematicsUnitModel>()
    const { handleSyncedChange, synced } = useSyncedStateContext()
    const onClick = () => {
        const newState = {}
        selectedIds.forEach((selectedId) => {
            const item = data.find((item) => item.id === selectedId)
            if (synced[item.telematicsId] || item.sync) {
                newState[item.telematicsId] = false
            }
        })
        handleSyncedChange(newState)
    }
    return (
        <HideOnBreakpoint
            replaceWith={
                <IconButton
                    size="small"
                    sx={{
                        color: (theme) => theme.palette.text.primary,
                    }}
                    onClick={onClick}
                >
                    <Icons.SyncDisabled
                        sx={{
                            width: '20px',
                            height: '20px',
                        }}
                    />
                </IconButton>
            }
        >
            <Button
                size="small"
                startIcon={<Icons.SyncDisabled />}
                onClick={onClick}
            >
                Sync OFF
            </Button>
        </HideOnBreakpoint>
    )
}

export default SyncOffManyFromListAction
