import { styled } from 'lib'
import { Stack, BoxContainer, Typography } from 'ui'

interface WidgetInfoContainerProps {
    maxHeight?: string
}

export const WidgetInfoContainer = styled(BoxContainer, {
    shouldForwardProp: (prop) => prop !== 'maxHeight',
})<WidgetInfoContainerProps>`
    justify-content: space-between;
    ${({ maxHeight }) => `max-height: ${maxHeight || '153px'}`};
    position: relative;
    justify-content: center;
    flex: 1;
    overflow: hidden;
`
export const WidgetInfoContentWrapper = styled(BoxContainer)`
    width: 100%;
    height: 100%;
    padding: 30px 20px 20px 20px;
`
export const WidgetListContainer = styled(Stack)`
    gap: 27px;
    width: 100%;
    height: 100%;
`

export const InfoTitle = styled(Typography)`
    background: white;
    padding: 0px 5px;
`
