import { type FC, type ReactElement, type ReactNode } from 'react'

import images from 'assets/images'
import { useMediaQuery } from 'lib'
import {
    type CardProps as CardPropsBase,
    NoResultsLayout,
    Box,
    Stack,
    type BoxProps,
    SectionTypography,
} from 'ui'

import { type CardProps, ColumnCard, RowCard } from './styled'

type CustomCardProps = Omit<CardPropsBase, 'title'> & CardProps

const CustomCard: FC<CustomCardProps> = (props) => {
    const matches = useMediaQuery((theme) => theme.props.mobileViewBreakpoint)
    const computedDirection = props.directionsm && matches ? props.directionsm : props.direction

    return computedDirection === 'column' ? <ColumnCard {...props} /> : <RowCard {...props} />
}

const imageChoices = {
    listEmpty: images.listFtu,
    listNotFound: images.listFilterNoResult,
}
export interface NoResultsCardProps extends CustomCardProps {
    action?: ReactNode
    imageSrc: ((images: typeof imageChoices) => string) | string
    imageProps: BoxProps<'img'>
    title: ReactNode
    subtitle?: ReactNode
    gridItem?: boolean
}
interface WithResultsLayoutType {
    children: ReactElement
    gridItem: boolean
}
const WithResultsLayout = ({ children, gridItem }: WithResultsLayoutType) => {
    if (!gridItem) {
        return children
    }

    return <NoResultsLayout>{children}</NoResultsLayout>
}
const NoResultsCard: FC<NoResultsCardProps> = ({
    gridItem = true,
    title,
    subtitle,
    action,
    imageSrc,
    imageProps,
    ...props
}) => {
    return (
        <WithResultsLayout gridItem={gridItem}>
            <CustomCard
                direction="column"
                {...props}
            >
                <Box
                    component="img"
                    src={typeof imageSrc === 'function' ? imageSrc(imageChoices) : imageSrc}
                    {...imageProps}
                    sx={{
                        objectFit: 'cover',
                        ...imageProps.sx,
                    }}
                    alt="No Results"
                />
                <Stack className="content">
                    <SectionTypography
                        variant="h5"
                        textAlign="left"
                    >
                        {title}
                    </SectionTypography>
                    {subtitle && (
                        <SectionTypography
                            variant="body1"
                            mb="6px"
                            as="div"
                        >
                            {subtitle}
                        </SectionTypography>
                    )}
                    {action}
                </Stack>
            </CustomCard>
        </WithResultsLayout>
    )
}
export default NoResultsCard
