import { type ComponentProps, type FC, type PropsWithChildren } from 'react'

import { ErrorBoundary } from 'lib'

import { StyledWidgetContainer, WidgetBaseDragIndicator, WidgetBaseResizeIndicator } from './styled'

const WidgetBaseContainer: FC<PropsWithChildren & ComponentProps<typeof StyledWidgetContainer>> = ({
    children,
    ...rest
}) => {
    return (
        <StyledWidgetContainer {...rest}>
            <ErrorBoundary>
                <WidgetBaseDragIndicator />
                <WidgetBaseResizeIndicator />
                {children}
            </ErrorBoundary>
        </StyledWidgetContainer>
    )
}

export default WidgetBaseContainer
