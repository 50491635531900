import { type ReactNode, useRef } from 'react'

import { useCreatePath, useRecordContext, useShowContext, useUpdate } from 'react-admin'

import Icons from 'assets/icons'
import { Gallery, GalleryItemGrid, type GalleryProps, getRecordPhotos } from 'components'
import GalleryItem from 'components/Gallery/GalleryItem'
import { serialize, useCanAccess, useNotify } from 'core'
import { useFinalErrorHandler } from 'core/errors'
import { maxFileSize, photoName } from 'resources/gallery'
import {
    woResource,
    type WorkOrderModel,
    type JobModel,
    woOperations,
    WoStatusKeys,
} from 'resources/workOrders'
import {
    InfoBadge,
    NonDisplayedInput,
    Typography,
    BoxContainer,
    Alert,
    Stack,
    Tooltip,
    Button,
} from 'ui'

const MAX_PHOTO_COUNT = 10

interface JobPhotosContentProps extends Pick<GalleryProps, 'onGalleryClose' | 'onGalleryStart'> {
    add: (index: number, file: string | File) => void
    remove: (index: number) => void
    count: number
    content: ReactNode
}

const JobPhotosContent = ({ remove, add, count, content, ...rest }: JobPhotosContentProps) => {
    let finalContent = <Alert severity="info">No Photos Added</Alert>
    const { record: woRecord } = useShowContext<WorkOrderModel>()

    const galleryOpener = useRef<{ open: (i: number) => void }>(null)
    const notify = useNotify()
    const getCanAccess = useCanAccess()
    const canAccess = getCanAccess(woOperations.photos, woRecord)
    const isClose = woRecord.status === WoStatusKeys.CLOSED

    const upload = async (event, index?: number) => {
        const file: File = event.target.files?.[0]
        if (!file) {
            return
        }

        const type = file.type
        if (!(type.startsWith('image') && !type.includes('svg'))) {
            notify({
                title: 'Invalid file format',
                type: 'error',
            })
        } else if (file.size > maxFileSize.size) {
            notify({
                title: maxFileSize.errorMessage,
                type: 'error',
            })
        } else {
            add(index, file)
        }
        event.target.value = null
    }

    if (count) {
        finalContent = (
            <Gallery
                {...rest}
                getOpener={(opener) => {
                    galleryOpener.current = opener
                }}
                onImageDelete={
                    isClose
                        ? null
                        : (galleryCtx) => {
                              const item = galleryCtx.getItemData(galleryCtx.currIndex)
                              remove(Number(item.element.id))
                          }
                }
            >
                {content}
            </Gallery>
        )
    }

    return (
        <Stack
            spacing="14px"
            mt="10px"
        >
            <BoxContainer justifyContent="space-between">
                <Typography
                    color="text.secondary"
                    variant="chartTitle"
                    component={BoxContainer}
                    gap="8px"
                >
                    Photos <InfoBadge badgeContent={count} />
                </Typography>
                <BoxContainer gap="17px">
                    <Tooltip
                        title={
                            canAccess.value
                                ? count >= MAX_PHOTO_COUNT
                                    ? 'Maximum image limit (10) reached'
                                    : 'Add up to 10 images, max size 30 MB each'
                                : canAccess.message
                        }
                    >
                        <label>
                            <NonDisplayedInput
                                type="file"
                                disabled={count >= MAX_PHOTO_COUNT || !canAccess.value}
                                onChange={upload}
                                accept="image/*"
                            />
                            <Button
                                variant="text"
                                color="primary"
                                size="small"
                                component="div"
                                disabled={count >= MAX_PHOTO_COUNT || !canAccess.value}
                                startIcon={<Icons.UploadFile />}
                            >
                                UPLOAD FILE
                            </Button>
                        </label>
                    </Tooltip>
                </BoxContainer>
            </BoxContainer>
            {finalContent}
        </Stack>
    )
}

interface JobPhotosProps extends Pick<JobPhotosContentProps, 'onGalleryClose' | 'onGalleryStart'> {
    inForm?: boolean
}

export const CardJobPhotos = (props: JobPhotosProps) => {
    const record = useRecordContext<JobModel>()

    const recordPhotos = getRecordPhotos(record)
    const photos = Object.keys(recordPhotos.files).map((key, i) => ({
        id: i,
        file: recordPhotos.files[key],
    }))

    const [update] = useUpdate()
    const { record: woRecord } = useShowContext<WorkOrderModel>()

    const notify = useNotify()
    const createPath = useCreatePath()

    const errorHandler = useFinalErrorHandler()
    const generalErrorHandler = (e, id: string) => {
        if (e[id]) {
            notify({
                title: e[id].message,
                type: 'error',
            })
        } else {
            errorHandler(e)
        }
    }
    const addPhoto = async (index: number, file: string | File) => {
        let freeItem = index
        if (typeof index === 'undefined') {
            freeItem = Number(photos.find((data) => !data.file).id)
        }
        const id = photoName(freeItem)
        const data = serialize({ [id]: file }, [{ name: id, parse: 'file' }])
        try {
            await update(
                createPath({ resource: woResource.resource, id: woRecord.id, type: 'edit' }) +
                    `/jobs`,
                { data, id: record.id },
                { returnPromise: true },
            )
        } catch (e) {
            generalErrorHandler(e, id)
        }
    }
    const removePhoto = async (index: number) => {
        const id = photoName(index)
        try {
            await update(
                createPath({ resource: woResource.resource, id: woRecord.id, type: 'edit' }) +
                    '/jobs',
                { data: { [id]: null }, id: record.id },
                { returnPromise: true },
            )
        } catch (e) {
            generalErrorHandler(e, id)
        }
    }
    return (
        <JobPhotosContent
            count={recordPhotos.count}
            content={
                <GalleryItemGrid
                    gridHeight="89px"
                    height="89px"
                    gap="16px"
                >
                    {photos.map((file) => {
                        return (
                            <GalleryItem
                                id={String(file.id)}
                                file={file.file}
                                key={file.id}
                                sx={{
                                    height: 'inherit',
                                }}
                            />
                        )
                    })}
                </GalleryItemGrid>
            }
            remove={removePhoto}
            add={addPhoto}
            {...props}
        />
    )
}
