import { type FC, useState } from 'react'

import { type GetListParams } from 'react-admin'
import { useFormContext } from 'react-hook-form'

import Icons from 'assets/icons'
import { MultiFormatScanner } from 'components'
import { useDataProvider, useFinalErrorHandler, useNotify } from 'core'
import { useFlags } from 'lib'
import { Button } from 'ui'

import { type PartModel } from '../types'
import { partsResource } from '../utils'

interface PartScannerProps {
    filter: GetListParams['filter']
}
const PartScanner: FC<PartScannerProps> = ({ filter }) => {
    const [isOpen, setIsOpen] = useState(false)

    const dataProvider = useDataProvider()
    const close = () => {
        setIsOpen(false)
    }
    const notify = useNotify()
    const { setValue } = useFormContext()
    const errorHandler = useFinalErrorHandler()
    const { flag4047UpcScanner } = useFlags()

    return (
        <>
            <MultiFormatScanner
                isOpen={isOpen}
                close={close}
                onDecode={async (decodedText, isUpcFormat) => {
                    try {
                        let part: PartModel
                        if (isUpcFormat) {
                            part = (
                                await dataProvider.getList(partsResource.resource, {
                                    filter: {
                                        universalProductCode: decodedText,
                                        ...filter,
                                    },
                                    pagination: { perPage: 1, page: 1 },
                                    sort: null,
                                })
                            ).data[0]
                            if (!part) {
                                throw new Error()
                            }
                        } else {
                            const url = new URL(decodedText)
                            const pathnameParts = url.pathname.split('/')
                            // in case if the url ends with "/"
                            const id = pathnameParts.pop() || pathnameParts.pop()
                            if (!id) {
                                throw new Error()
                            }
                            part = (
                                await dataProvider
                                    .getOne<PartModel>(partsResource.resource, {
                                        id,
                                    })
                                    .catch((err) => {
                                        if (err.status === 404) {
                                            throw new Error()
                                        }
                                        errorHandler(err)
                                        return null
                                    })
                            )?.data
                        }

                        if (!part) {
                            return
                        }

                        setValue('part', part.id, {
                            shouldDirty: true,
                            shouldTouch: true,
                        })
                        notify('Part Selected')
                    } catch {
                        notify({
                            title: 'No Part information found on scanned code',
                            type: 'error',
                        })
                    } finally {
                        close()
                    }
                }}
            />
            <Button
                startIcon={<Icons.QrCodeScanner />}
                variant="text"
                size="small"
                onClick={() => setIsOpen(true)}
            >
                {flag4047UpcScanner ? 'SCAN BARCODE/QR CODE' : 'SCAN QR CODE'}
            </Button>
        </>
    )
}
export default PartScanner
