import { type FC } from 'react'

import { inject, observer } from 'mobx-react'
import { Link } from 'react-admin'

import Icons from 'assets/icons'
import { supportLauncherSelector, urls } from 'configs'
import { useLogout, type AuthStore, useNotify } from 'core'
import { globalClassNames, useLocationChange, withColor } from 'lib'
import {
    paperClasses,
    Menu,
    MenuList,
    Typography,
    Divider,
    type MenuProps,
    BoxContainer,
    Box,
    listClasses,
} from 'ui'

import ProfileMenuItem from './SidebarProfileMenuItem'
import UserAvatar from './UserAvatar'

interface SidebarProfileMenuProps extends Pick<MenuProps, 'anchorOrigin' | 'transformOrigin'> {
    anchorEl: HTMLElement | null
    onClose: () => void
}

const SidebarProfileMenu: FC<SidebarProfileMenuProps> = ({
    anchorEl,
    onClose,
    anchorOrigin,
    transformOrigin,
}) => {
    const open = Boolean(anchorEl)

    return (
        <Menu
            sx={{
                [`& .${paperClasses.root}`]: {
                    width: '240px',
                },
                [`& .${listClasses.root}`]: {
                    padding: 0,
                },
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            onClose={onClose}
        >
            <Box
                component="li"
                role="menuitem"
                p="8px 0"
            >
                <MenuContent onClose={onClose} />
            </Box>
        </Menu>
    )
}

const MenuContent: FC<Omit<SidebarProfileMenuProps, 'anchorEl'> & { auth?: AuthStore }> = inject(
    'auth',
)(
    observer(({ auth, onClose }) => {
        const notify = useNotify()
        const { user } = auth
        const logout = useLogout({
            onSuccess: () => {
                notify({
                    title: "You've been successfully logged out.",
                    type: 'info',
                })
            },
        })
        useLocationChange(() => onClose())

        return (
            <Box>
                <BoxContainer
                    gap="18px"
                    p="12px"
                >
                    <UserAvatar size="40px" />
                    <div className={globalClassNames.ellipsis}>
                        <Typography
                            variant="subtitle2"
                            color={withColor('text.primary')}
                            className={globalClassNames.ellipsis}
                        >
                            {user.name}
                        </Typography>
                        <Typography
                            variant="helperText"
                            color={withColor('text.secondary')}
                            className={globalClassNames.ellipsis}
                        >
                            {user.email}
                        </Typography>
                    </div>
                </BoxContainer>
                <Divider />
                <MenuList>
                    <Link
                        to={urls.userProfile}
                        role="menuitem"
                    >
                        <ProfileMenuItem Icon={Icons.Profile}>Personal Account</ProfileMenuItem>
                    </Link>
                    <ProfileMenuItem
                        Icon={Icons.SupportOutlined}
                        className={supportLauncherSelector}
                    >
                        Help and Support
                    </ProfileMenuItem>
                    <Divider sx={{ m: '0 !important' }} />
                    <ProfileMenuItem
                        onClick={() => logout()}
                        Icon={Icons.Logout}
                    >
                        Log Out
                    </ProfileMenuItem>
                </MenuList>
            </Box>
        )
    }),
)

export default SidebarProfileMenu
