import Icons from 'assets/icons'
import { type ActionChildren, downloadOneAction } from 'core'

import { type InvoiceModel } from '../types'

export const downloadInvoicePdfAction = (record: InvoiceModel, children: ActionChildren) =>
    downloadOneAction({
        children: (params) =>
            children({
                ...params,
                Icon: Icons.Pdf,
                title: 'Export PDF',
            }),
        id: record.id,
        filename: record.number,
        type: 'pdf',
    })
