import { useRecordContext } from 'react-admin'

import Icons from 'assets/icons'
import { baseOperations, useCanAccess } from 'core'
import { isVendorWo, type WorkOrderModel } from 'resources/workOrders'
import { IconBox } from 'ui'

import WorkOrderDrawerToggler from './WorkOrderDrawerToggler'

const WorkOrderEditButton = () => {
    const record = useRecordContext<WorkOrderModel>()
    const getCanAccess = useCanAccess()
    const canAccess = getCanAccess(baseOperations.edit)

    if (!canAccess.value) {
        return null
    }

    return (
        <WorkOrderDrawerToggler
            id={record.id}
            hiddenFields={{ poNumber: isVendorWo(record) }}
        >
            {({ onClick }) => (
                <IconBox
                    onClick={onClick}
                    title="Edit"
                >
                    <Icons.Edit />
                </IconBox>
            )}
        </WorkOrderDrawerToggler>
    )
}

export default WorkOrderEditButton
