import { type FC } from 'react'

import { inject, observer } from 'mobx-react'

import { TextInput } from 'components'
import { type AuthStore, validateMaxLength } from 'core'

import customerFields from '../dataConfig'

const maxLengthValidation = validateMaxLength(40)

interface Props {
    disabled?: boolean
    source?: string
    defaultValue?: string
}

const CustomerPONumberInput: FC<Props> = inject('auth')(
    observer(
        ({
            auth,
            disabled,
            source = customerFields.poNumber.source,
            defaultValue,
        }: { auth: AuthStore } & Props) => {
            if (!auth.companySettings.hasInvoicing) {
                return null
            }

            return (
                <TextInput
                    disabled={disabled}
                    label={customerFields.poNumber.label}
                    source={source}
                    validate={maxLengthValidation}
                    defaultValue={defaultValue}
                />
            )
        },
    ),
)

export default CustomerPONumberInput
