import { type NoInfer } from 'appTypes'
import { type Column, type DataListDetails } from 'components'
import { BoxContainer, StatusText } from 'ui'

import { BaseStatusContent, UnitStatusDrawerToggler } from '../components'
import { type UnitStatusModel } from '../types'

const source = 'name' as const
const label = 'Name'

const value = (record: UnitStatusModel) => {
    if (!record) {
        return null
    }
    return (
        <BoxContainer gap="4px">
            <UnitStatusDrawerToggler id={record.id}>
                {(open) => (
                    <BaseStatusContent
                        color={record.color}
                        onClick={open}
                    >
                        {record.name}
                    </BaseStatusContent>
                )}
            </UnitStatusDrawerToggler>
            {record.isDefault ? (
                <StatusText
                    height="fit-content"
                    color={(theme) => theme.palette.primary.main}
                    bgcolor={(theme) => theme.palette.primary.main}
                >
                    Default
                </StatusText>
            ) : null}
        </BoxContainer>
    )
}

const tableColumn = <Source extends string = typeof source>() =>
    ({
        field: source as NoInfer<Source>,
        headerName: label,
        renderCell: ({ row }) => value(row),
    }) as const satisfies Column

const dataCardRow = <Source extends string = typeof source>() =>
    ({
        source: source as NoInfer<Source>,
        label,
        render: (_, data) => value(data),
    }) as const satisfies DataListDetails<any, any>

const name = {
    source,
    label,
    value,
    tableColumn,
    dataCardRow,
}

export default name
