import { useWatch } from 'react-hook-form'

import { formatMoney } from 'components'
import { BoxContainer, Typography } from 'ui'

interface TotalFieldProps {
    inputOne: string
    inputTwo: string
}

export const TotalFormField = ({ inputOne, inputTwo }: TotalFieldProps) => {
    const qtyHours = useWatch({ name: inputOne })
    const priceCost = useWatch({ name: inputTwo })

    return formatMoney(qtyHours * priceCost)
}

interface MoneyFormTotalProps extends TotalFieldProps {
    title: string
}

const MoneyFormTotal = ({ title, inputOne, inputTwo }: MoneyFormTotalProps) => {
    return (
        <BoxContainer
            justifyContent="end"
            width="inherit"
        >
            <Typography
                component="span"
                variant="subtitle1"
                color="text.secondary"
                marginBottom="34px"
                marginTop="11px"
                sx={{ display: 'flex', float: 'right' }}
            >
                {title} &nbsp;
                <Typography
                    color="text.primary"
                    variant="subtitle1"
                >
                    <TotalFormField
                        inputOne={inputOne}
                        inputTwo={inputTwo}
                    />
                </Typography>
            </Typography>
        </BoxContainer>
    )
}

export default MoneyFormTotal
