import { type FC, type ReactElement } from 'react'

import { type Identifier } from 'appTypes'
import { useOpenUtilityDrawer, UtilityDrawerEditor } from 'components'
import { type Serializer } from 'core'
import {
    deleteInvoiceItemActionParams,
    getInvoiceItemResource,
    type InvoiceModel,
    type InvoicePartModel,
    InvoiceStatusKeys,
    InvoiceTypeKeys,
} from 'resources/invoices'
import { PartScanner } from 'resources/parts'

import PartForm, { type PartFormProps } from './PartForm'

interface InvoicePartDrawerProps extends Pick<PartFormProps, 'isDisabled'> {
    children: (open: () => void) => ReactElement
    invoice: InvoiceModel
    id?: Identifier
    type?: InvoiceTypeKeys
}

const InvoicePartDrawer = ({
    invoice,
    id,
    children,
    type = InvoiceTypeKeys.STANDARD,
    isDisabled,
}: InvoicePartDrawerProps) => {
    const open = useOpenUtilityDrawer()
    const resource = getInvoiceItemResource(invoice.id, id ? undefined : 'add_part')
    const readOnly = invoice.status !== InvoiceStatusKeys.OPEN

    const extra: InvoicePartExtra = {
        invoice,
    }
    return children(() => {
        open({
            extraArgs: {
                resource,
                id,
            },
            drawerArgs: {
                title: id ? <EditTitle readOnly={readOnly} /> : 'Add Part',
                renderWrapper: (props) => (
                    <UtilityDrawerEditor
                        serializer={serializer}
                        {...props}
                    />
                ),
                renderContent: () => (
                    <FormContent
                        isDisabled={isDisabled}
                        id={id}
                        type={type}
                        record={invoice}
                    />
                ),
                renderTopRight:
                    type === InvoiceTypeKeys.WORK_ORDER || readOnly
                        ? null
                        : (render) =>
                              id ? (
                                  render(deleteInvoiceItemActionParams)
                              ) : (
                                  <PartScanner
                                      filter={{
                                          contextType: 'invoice-items',
                                          contextId: invoice.id,
                                      }}
                                  />
                              ),
                renderBottomRight: readOnly
                    ? null
                    : (render) =>
                          render({
                              label: id ? undefined : 'Add',
                          }),
                renderBottomLeft: readOnly ? (render) => render({ children: 'Close' }) : undefined,
            },
            extra,
        })
    })
}

export default InvoicePartDrawer

export interface InvoicePartExtra {
    invoice: InvoiceModel
}
const EditTitle: FC<{ readOnly: boolean }> = ({ readOnly }) => {
    if (readOnly) {
        return <>View Part</>
    }

    return <>Edit Part</>
}

interface FormContentProps extends Pick<PartFormProps, 'isDisabled'> {
    id?: Identifier
    type: InvoiceTypeKeys
    record: InvoiceModel
}

const FormContent: FC<FormContentProps> = ({ isDisabled, id, type, record }) => {
    return (
        <PartForm
            isHidden={(source) => {
                if (type === InvoiceTypeKeys.STANDARD) {
                    return source === 'orderQuantity' || source === 'position'
                }
            }}
            isDisabled={(source) => {
                if (!id) {
                    return isDisabled?.(source)
                }

                if (record.status !== InvoiceStatusKeys.OPEN) {
                    return true
                }

                if (type === InvoiceTypeKeys.WORK_ORDER) {
                    return source === 'part'
                }
            }}
        />
    )
}

const serializer: Serializer<InvoicePartModel> = ['part', 'quantity', 'price']
