import { type FC, type ReactElement } from 'react'

import { useListContext } from 'react-admin'

import { type Identifier } from 'appTypes'
import { UtilityDrawerEditor, useOpenUtilityDrawer } from 'components'
import { type Serializer, useResource } from 'core'

import { deletePaymentAction } from '../actions'
import { type PaymentModel } from '../types'

import PaymentForm from './PaymentForm'
import { type PaymentDrawerTogglerExtra, type PaymentFormDefaultValues } from './types'

export interface PaymentDrawerTogglerProps {
    id?: Identifier
    children: (open: () => void) => ReactElement
    readOnly?: boolean
    defaultValues?: PaymentFormDefaultValues
}

const PaymentDrawerToggler: FC<PaymentDrawerTogglerProps> = ({
    children,
    id,
    readOnly,
    defaultValues,
}) => {
    const open = useOpenUtilityDrawer()
    const resource = useResource()

    const listContext = useListContext()

    return children(() => {
        open({
            extraArgs: {
                id,
                resource,
                listContext,
            },
            drawerArgs: {
                title: id ? (readOnly ? 'View Payment' : 'Edit Payment') : 'Add Payment',
                renderWrapper: (params) => (
                    <UtilityDrawerEditor
                        {...params}
                        serializer={paymentSerializer}
                        defaultValues={defaultValues}
                    />
                ),
                renderContent: () => <PaymentForm />,
                renderBottomRight: readOnly
                    ? () => null
                    : (render) => render({ label: id ? undefined : 'Add Payment' }),
                renderTopRight: id && !readOnly ? (render) => render(deletePaymentAction()) : null,
                renderBottomLeft: readOnly ? (render) => render({ children: 'Close' }) : undefined,
            },
            extra: {
                disabledFields: readOnly ? () => true : undefined,
            } satisfies PaymentDrawerTogglerExtra,
        })
    })
}

export default PaymentDrawerToggler

const paymentSerializer: Serializer<PaymentModel> = [
    'id',
    { name: 'amount', parse: 'number' },
    { name: 'date', parse: 'date' },
    {
        name: 'method',
        parse: 'emptyToNull',
    },
    'number',
]
