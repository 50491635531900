import { type FC } from 'react'

import { type Identifier } from 'appTypes'
import { CollapsibleInfoCard, EditButton, InfoCardHeader } from 'components'
import { type ResourceType } from 'core'
import { globalClassNames } from 'lib'
import { type InvoiceJobModel } from 'resources/invoices'
import { ck14Fields, ck33Fields } from 'resources/vmrs'
import { JobDrawerToggler, woResource } from 'resources/workOrders'
import { Anchor, Typography } from 'ui'
import { pathJoin } from 'utils'

import { ItemsContent } from '../../components'

const getJobResource = (id: Identifier): ResourceType => ({
    name: 'jobs',
    resource: pathJoin(woResource.resource, id, 'jobs'),
})
const WoItemsCard: FC<{ job: InvoiceJobModel; isCanceled: boolean }> = ({ job, isCanceled }) => {
    const jobResource = getJobResource(job.workOrder)
    return (
        <CollapsibleInfoCard
            defaultOpen
            disableActiveState
            sameParent
        >
            <InfoCardHeader
                noDivider
                title={
                    <div className={globalClassNames.ellipsis}>
                        {isCanceled ? (
                            job.name
                        ) : (
                            <JobDrawerToggler
                                id={job.id}
                                mode="view"
                                resource={jobResource}
                            >
                                {(open) => <Anchor onClick={open}>{job.name}</Anchor>}
                            </JobDrawerToggler>
                        )}
                        <br />
                        <Typography variant="helperText">
                            {ck33Fields.self.value(job.componentData)}
                        </Typography>
                        <br />
                        <Typography variant="helperText">
                            {ck14Fields.self.value(job?.reasonForRepairData)}
                        </Typography>
                    </div>
                }
                action={
                    isCanceled ? null : (
                        <JobDrawerToggler
                            id={job.id}
                            mode="view"
                            resource={jobResource}
                        >
                            {(open) => <EditButton onClick={open} />}
                        </JobDrawerToggler>
                    )
                }
            />
            <ItemsContent invoiceItems={job.invoiceItems} />
        </CollapsibleInfoCard>
    )
}

export default WoItemsCard
