import { type FC } from 'react'

import { ActionsMenu, type MenuActionProps, useListController } from 'core'
import { SimpleScrollbar, Stack, Typography } from 'ui'

import { type UnitStatusModel } from '../types'
import { unitStatusResource } from '../utils'

import BaseStatusContent from './BaseStatusContent'

interface StatusMenuProps extends Pick<MenuActionProps, 'renderToggler'> {}
const StatusMenu: FC<StatusMenuProps> = ({ renderToggler }) => {
    const listController = useListController<UnitStatusModel>({
        resource: unitStatusResource.resource,
        perPage: 1000,
    })

    if (listController.isLoading || listController.error) {
        return null
    }

    const data = listController.data

    return (
        <ActionsMenu
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            renderToggler={renderToggler}
            actions={(params, { children }) => [
                <SimpleScrollbar
                    style={{ maxHeight: '193px', width: '144px' }}
                    key="add-wo"
                >
                    <Stack
                        spacing="6px"
                        px="12px"
                    >
                        {data.map((status) => (
                            <Typography
                                key={status.id}
                                variant="menuItem"
                            >
                                <BaseStatusContent color={status.color}>
                                    {status.name}
                                </BaseStatusContent>
                            </Typography>
                        ))}
                    </Stack>
                </SimpleScrollbar>,
            ]}
        />
    )
}

export default StatusMenu
