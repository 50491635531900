import { type UnitModel } from './types'

export const isUnitArchived = (unit: Pick<UnitModel, 'archived'>) => Boolean(unit?.archived)

export const unitAspects = {
    totals: 'totals',
    costPerMeter: 'cost_per_meter',
    downtime: 'downtime',
}

export const equipmentHasBodySource = 'equipmentHasBody' as keyof UnitModel
